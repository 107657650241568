import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Dropdown, Nav, OverlayTrigger, Row, Tab, Tooltip } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import Form from "react-bootstrap/Form";
import InputGroup from 'react-bootstrap/InputGroup';
import DataTable, { createTheme } from 'react-data-table-component';
import { useLocation, useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import loadingGif from "../../../../../Assets/Images/Animations/loading-management-console.gif";
import serviceLogo from '../../../../../Assets/Images/Illustrations/csp.svg';
import EmptyDataTableFilterSearch from '../../../../../Assets/Images/Illustrations/EmptyDataTableFilterSearch.svg';
import EmptyServiceProvision from '../../../../../Assets/Images/Illustrations/EmptyServiceProvision.svg';
import EmptyServiceSubscription from '../../../../../Assets/Images/Illustrations/EmptyServiceSubscription.svg';
import failureImg from '../../../../../Assets/Images/Illustrations/failure.svg';
import SearchDropdown from "../../../../../GlobalComponents/SearchDropdown";
import Toast from '../../../../../GlobalComponents/Toast';
import { api } from '../../../../../Utils/API';
import { CSP_BILLING_TERMS_TYPE, CSP_EDIT_QUANTITY_POST, CSP_SERVICE_MANAGEMENT_ACCOUNT_DETAILS, CSP_STATUS_TYPE, CSP_SUBSCRIPTION_DETAILS, CSP_SUBSCRIPTION_TYPE, GET_CSP_SUBSCRIPTION_TABLE, GET_CUSTOMERS_LIST, GET_SERVICES } from '../../../../../Utils/GlobalConstants';
import QuantityEditor from '../QuantityEditer';
import styles from './cspServiceManagement.module.scss';
import CSPChangeStatus from './LocalComponents/Actions/CSPChangeStatus';
import CSPEditQuantity from './LocalComponents/Actions/CSPEditQuantity';
import CSPMigrate from './LocalComponents/Actions/CSPMigrate';
import CSPPurchaseAddOns from './LocalComponents/Actions/CSPPurchaseAddOns';
import CSPRenewalSettings from './LocalComponents/Actions/CSPRenewalSettings';
import CSPTrialToPaid from './LocalComponents/Actions/CSPTrialToPaid';
import CSPUpgrade from './LocalComponents/Actions/CSPUpgrade';
import CreateGDAP from './LocalComponents/CreateGDAP';
import Expandable from './LocalComponents/ExpandableTable';
import CSPFilterBox from './LocalComponents/FilterBox';
import GDAPRelationship from './LocalComponents/GDAPRelationship';
import LazyTable from './LocalComponents/LazyTable';

const CspServiceManagement = ({ toggleDrawer, setSelectService, setSelectCategory, Logout }) => {

    const alphanumericRegex = /^[a-zA-Z0-9-]{0,50}$/;                                // RegEx for PO Number
    const [isreadOnly, setIsreadOnly] = useState(localStorage.getItem("read_array") && localStorage.getItem("read_array").includes("Manage") ? true : false);

    const [isCloudAdmin, setIsCloudAdmin] = useState(localStorage.getItem("curentRole") && localStorage.getItem("curentRole").toLowerCase().includes("cloud admin view") ? true : false);
    const location = useLocation();                                                 // use to access data from other page data
    const navigate = useNavigate();                                                 // to use navigate function from react router dom
    const [copied, setCopied] = useState("Click to copy");                          // Copy Use State
    const searchInputRef = useRef();                                                // Search Input Box
    const [searchIconFlag, setSearchIconFlag] = useState(location.state?.planName !== null ? true : false);                    // to open and close search box
    const [searchValueTable, setSearchValueTable] = useState(location.state?.planName !== null ? location.state?.planName : null);                 // storing searching value in the search input box
    const [filterFlag, setFilterFlag] = useState(true);                            // to show active and deactive filter states 
    const [showFilterBox, setShowFilterBox] = useState(false);                      // showing filter box
    const [status, setStatus] = useState(["ACTIVE","SUSPENDED","PENDING"]);         // getTableData() status
    const [billingTerm, setBillingTerm] = useState(null);                           // getTableData() Commitment Term (Billing Frequency
    const [planType, setPlanType] = useState(null);                                 // getTableData() plan type
    const [subscriptionTableAPIData, setSubscriptionTableAPIData] = useState();     // storing subscriptions details data
    const [editQuantityActionsValue, setEditQuantityActionsValue] = useState(null);  // for storing default quantity from api
    const [newQuantity, setNewQuantity] = useState(null);                            // storing new qauntity entered by user
    let ColumnNameSubscriptionTable = ["Commitment Term (Billing Frequency)", "Quantity", "Auto Renew", "RRP (Inc GST)", "Reseller Price (Ex GST)"];   // table headers used to make object of react data table component for actions popup
    const [columnsSubscriptionTable, setColumnsSubscriptionTable] = useState([]);                                       // columns used to display column headers in the react data table component
    const [tableSubscriptionTable, setTableSubscriptionTable] = useState([]);         // subscription listing table
    const [actionsSubscriptionId, setActionsSubscriptionId] = useState(location.state?.subscriptionId !== null ? location.state?.subscriptionId : null);
    const [actionsPlanName, setActionsPlanName] = useState(null);                     // plan name while performing any action

    // information for actions popup
    const [editQuantityActionPopupModalOpen, setEditQuantityActionPopupModalOpen] = useState(false); 
    const [upgradeActionPopupModalOpen, setUpgradeActionPopupModalOpen] = useState(false);
    const [renewalSettingsActionPopupModalOpen, setRenewalSettingsActionPopupModalOpen] = useState(false);
    const [changeStatusActionPopupModalOpen, setChangeStatusActionPopupModalOpen] = useState(false);
    const [trialToPaidActionPopupModalOpen, setTrialToPaidActionPopupModalOpen] = useState(false);
    const [purchaseAddOnsActionPopupModalOpen, setPurchaseAddOnsActionPopupModalOpen] = useState(false);
    const [migrationActionPopupModalOpen, setMigrationActionPopupModalOpen] = useState(false);
    const [actionCompletedRefreshSubscriptionTable, setActionCompletedRefreshSubscriptionTable] = useState(false)

    // Table Parameters
    const [pageNo, setPageNo] = useState(1);                                        // table active page number
    const [pageSize, setPageSize] = useState(15);                                    // table active page size
    const [totalRows, setTotalRows] = useState(0);                                  // table total count of data from api 
    const [table, setTable] = useState([]);                                         // data state used to display data in react data table component
    let ColumnName = ["Plan", "Quantity", "Commitment Term (Billing Frequency)",
        "Start Date", "Renewal Date", "Subscription Type", "Status", "Info", "Actions"];     // table headers used to make object of react data table component 
    let ColumnNameReadOnly = ["Plan", "Quantity", "Commitment Term (Billing Frequency)",
        "Start Date", "Renewal Date", "Subscription Type", "Status", "Info"];     // table headers used to make object of react data table component 
   
    // Order Confirmation Details Table
    let detailsColumn = ["Plan","SQU ID", "Commitment Term (Billing Frequency)", "Unit Price", "Quantity", "Sub-Total Price"];
    const [detailsTable, setDetailsTable] = useState([]);
    const [detailsTableColumn, setDetailsTableColumn] = useState([]);
    const [serverErrorUser, setServerErrorUser] = useState(false);                   // server error check state in table during api call to load data
    const [emptyUser, setEmptyUser] = useState(false);                               // empty table check state in table during api call to load data
    const [emptyUserSearch, setEmptyUserSearch] = useState(false);                   // empty search response check state in table during api call to load data
    const [emptyUserFilters, setEmptyUserFilters] = useState(true);                 // empty filter response check state in table during api call to load data
    const [isDescStartDate, setIsDescStartDate] = useState(true);                    // sort state check for start date column
    const [isDescRenewalDate, setIsDescRenewalDate] = useState(null);                // sort state check for renewal date column
    const [sortCol, setSortCol] = useState("sortingStartDate");                      // getTableData() sorting column name
    const [sortDir, setSortDir] = useState("DESC");                                  // getTableData() sorting direction ie. "DESC" for descending and "ASC" for ascending
    const [customerId, setCustomerId]                                                // storing customer id
        = useState(location.state?.customerId !== null ? location.state?.customerId : "");
    const [resellerId, setResellerId]
        = useState((location.state !== null && location.state !== "") ? location.state.resellerId : "");  // storing reseller id
    
    const [subscriptionId, setSubscriptionId] = useState(null);                      // storing subscription id
    const [serviceId, setServiceId] = useState(null);                                // service name for dropdown
    const [serviceData, setServiceData] = useState(null);                            // data in service dropdown
    const [customerData, setCustomerData] = useState([]);                            // data in customer dropdown
    const [modalOpen, setModalOpen] = useState(false);                               // modal opening
    const [additionalDetails, setAdditionalDetails] = useState(null);                // storing details from api
    const [loading, setLoading] = useState(false);                                   // loading state while table loads
    const [infinityLoading, setInfinityLoading] = useState(false);                   // loading state while table loads
    const [provisionRefreshIconFlag, setProvisionRefreshIconFlag] = useState(false); // refresh icon to be displayed when service is provisioned
    const [initialSubscriptionTableCall, setInitialSubscriptionTableCall]
        = useState(null);                                                            // initial subscription loading when service is provisioned
    const [isNcePlan, setIsNcePlan] = useState(null);                                // to check whether it is nce plan or legacy plan 
    const [poNumber, setPoNumber] = useState(null);
    const [editingQuantityRowId, setEditingQuantityRowId] = useState(null);
    const [editedQuantity, setEditedQuantity] = useState(null);
    const [isEditingQuantity, setIsEditingQuantity] = useState(false);
    const [minQuantity, setMinQuantity] = useState(null);                         // for setting min quantity
    const [maxQuantity, setMaxQuantity] = useState(null);                         // for setting max quantity
    const editQuantityRef = useRef();
    const [editQuantityModalOpen, setEditQuantityModalOpen] = useState(false);   // inline edit quntity popup
    const [editQuantityErrorModalOpen, setEditQuantityErrorModalOpen] = useState(false);
    const [confirmLoadingFlag, setConfirmLoadingFlag] = useState(false);       // loading state while calling api
    const [errorFlag, setErrorFlag] = useState(false);                        // to display error flag true or false while getting error
    const [errorMessage, setErrorMessage] = useState(null);                   // to display error message
    const [filterApplied, setFilterApplied] = useState(false);                        // filter in gettabledata

    //filterbox usestates
    const [subscriptionTypeData, setSubscriptionTypeData] = useState(null);                             // data in service dropdown
    const [statusData, setStatusData] = useState(null);                               // data in status dropdown
    const [billingTypeData, setBillingTypeData] = useState(null);                     // data in account type dropdown

    //info icon popup showing subscription details
    const [isSubscriptionDetailsPopupOpen, setIsSubscriptionDetailsPopupOpen] = useState(false);                        // Subscription Details Popup Box
    const [subscriptionDetailIsLoadingFlag, setSubscriptionDetailIsLoadingFlag] = useState(false)                       // Subscription is Loading Popup
    const [subscriptionDetailsPlanName, setSubscriptionDetailsPlanName] = useState("Loading...");
    const [subscriptionDetailsVendorSubscriptionId, setSubscriptionDetailsVendorSubscriptionId] = useState("Loading...");
    const [subscriptionDetailsLicenceAutoRenewal, setSubscriptionDetailsLicenceAutoRenewal] = useState("Loading...");
    const [subscriptionDetailsStartDate, setSubscriptionDetailsStartDate] = useState("Loading...");
    const [subscriptionDetailsEndDate, setSubscriptionDetailsEndDate] = useState("Loading...");
    const [subscriptionDetailsIsTrial, setSubscriptionDetailsIsTrial] = useState(null);

    // Search Value for Service Name dropdown
    const [serviceNameDefaultValue, setServiceNameDefaultValue] = useState("Select Service Name");
    const [serviceNameSearchValue, setServiceNameSearchValue] = useState();
    const [serviceName, setServiceName] = useState([]);
    const [serviceNameFiltered, setServiceNameFiltered] = useState(serviceName);
    const updatedServiceName = [];

    // Search Value for Customer Name dropdown
    const [customerNameDefaultValue, setCustomerNameDefaultValue] = useState("Select Customer Name");
    const [customerNameSearchValue, setCustomerNameSearchValue] = useState();
    const [customerName, setCustomerName] = useState([]);
    const [customerNameFiltered, setCustomerNameFiltered] = useState(customerName);
    const updatedCustomerName = [];

    // Use State for Toasts
    const [toastSuccess, setToastSuccess] = useState(false);                        // toast message displaying success message
    const [toastError, setToastError] = useState(false);                            // toast message displaying error message
    const [toastMessage, setToastMessage] = useState("");                           // toast message  
    const [isTermsChecked, setIsTermsChecked] = useState(localStorage.getItem("cloud_terms_and_conditions") == "false" ? false : true);                          // check whether terms and condition is checked or not

    // cloud portal details
    const [tabName, setTabName] = useState("Cloud Portal Details");                                          // Default Tab
    const [scrollToDivTop, setScrollToDivTop] = useState(false);

    const [autoRefreshInterval, setAutoRefreshInterval] = useState(5000); // 5 seconds default
    const [isAutoRefreshEnabled, setIsAutoRefreshEnabled] = useState(false);
    const [isFilterOrSearchActive, setIsFilterOrSearchActive] = useState(false);
    const [isActionInProgress, setIsActionInProgress] = useState(false);
    const [isEditingInProgress, setIsEditingInProgress] = useState(false);
    const [isSorting, setIsSorting] = useState(false);
    const [hasPendingStatus, setHasPendingStatus] = useState(false);
    const [pagesWithPending, setPagesWithPending] = useState(new Set());
    const [loadedPages, setLoadedPages] = useState(new Set([1]));
    const [actionPageNo, setActionPageNo] = useState(null);
    const [tableData, setTableData] = useState({});

     // Header Authorization for General API's
     const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    const handleEditQuantitySave = (row) => {
        setEditQuantityModalOpen(true);
    };

    const handleEditQuantityClose = () => {
        setEditingQuantityRowId();
        setEditedQuantity(null);
        setIsEditingQuantity(false);
        setMinQuantity(null);
        setMaxQuantity(null);
        setIsEditingInProgress(false);
        if (!isFilterOrSearchActive) {
        setIsAutoRefreshEnabled(true);
        }
    };

    const handleEditQuantityClick = (row) => {
        setEditedQuantity(row["Quantity"]);
        setEditingQuantityRowId(row.subscriptionId);
        setIsEditingInProgress(true);
        setActionsSubscriptionId(row.subscriptionId);
        setIsAutoRefreshEnabled(false);

        // api for getting min and max quantity
        api.get(CSP_SUBSCRIPTION_DETAILS + row.subscriptionId, config)
            .then(resp => {
                setLoading(false);
                setMaxQuantity(resp.data.maxQuantity);
                setMinQuantity(resp.data.minQuantity);
            })
            .catch(error => {
                setIsAutoRefreshEnabled(false);
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Copied to Clipboard Function
    const copyToClipboard = (domainName) => {
        navigator.clipboard.writeText(domainName);
        setCopied("Copied!");
        setTimeout(() => {
            setCopied("Click to copy");
        }, 1900);
    };

    // Function to call all dropdown values in Service Section in Filter Box
    async function GetAllServices() {
        await api.get(GET_SERVICES, config)
            .then(resp => {
                let serviceArray = []
                resp.data.map((service) => {
                    if (service.serviceType == "ONLINE") {
                        serviceArray.push(service);
                    }
                })
                setServiceData(serviceArray);
                setServiceNameFiltered(serviceArray);

                for (let i in resp.data) {
                    if (resp.data[i].integrationCode === "microsoftsaas") {
                        setServiceId(resp.data[i].id);
                        setServiceNameDefaultValue(resp.data[i].name) // serviceName.push(resp.data[i].name);
                    }
                }

                for (let i in resp.data) {
                    if (!serviceName.includes(resp.data[i].name)) {
                        serviceName.push(resp.data[i].name);
                    }
                }
            })
            .catch(error => {
                setIsAutoRefreshEnabled(false);
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Function to add data in View Subscription Details Popup
    async function GetSubscriptionDetailsPopup(subscriptionid) {
        setSubscriptionDetailIsLoadingFlag(true);
        await api
            .get(`${CSP_SUBSCRIPTION_DETAILS + subscriptionid}?requestedResellerCode=${resellerId}`, config)
            .then((resp) => {
                setSubscriptionDetailsPlanName(resp.data.planName);
                setSubscriptionDetailsVendorSubscriptionId(
                    resp.data.vendorSubscriptionId
                );
                setSubscriptionDetailsLicenceAutoRenewal(resp.data.autoRenew);
                setSubscriptionDetailsStartDate(resp.data.startDate);
                setSubscriptionDetailsEndDate(resp.data.subscriptionEndDate);
                setSubscriptionDetailsIsTrial(resp.data.isTrial);
                setSubscriptionDetailIsLoadingFlag(false);
            })
            .catch((error) => {
                // handle error
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                    setSubscriptionDetailIsLoadingFlag(false);
                }
            });
    }

    //for actions core data 
    async function GetActionsData(subscriptionId) {
        (subscriptionId === undefined || subscriptionId === null) ? subscriptionId = "" : subscriptionId = subscriptionId;
        await api.get(`${CSP_SUBSCRIPTION_DETAILS}${subscriptionId}`, config)
            .then(resp => {
                setLoading(false);
                if (resp.status == 204) {

                }
                // handle success
                else {
                    let f = [];
                    setSubscriptionTableAPIData(resp.data);
                    //to check whether it is nce plan or legacy plan
                    if (resp.data.isNcePlan === true) {
                        setIsNcePlan(true);
                    } else {
                        setIsNcePlan(false);
                    }
                    setMaxQuantity(resp.data.maxQuantity);
                    setMinQuantity(resp.data.minQuantity);
                    setNewQuantity(resp.data.quantity);
                    setEditQuantityActionsValue(resp.data.quantity);
                    f.push
                        (
                            {
                                "Commitment Term (Billing Frequency)": resp.data.billingTerm,
                                "Assigned Seats": resp.data.assignedSeat,
                                "Quantity": resp.data.quantity,
                                "Status": resp.data.status === 'ACTIVE' ? (
                                    <div className={`${styles['status-gws']}`}>
                                        <span className="status-active"></span>
                                        <div className="py-1 text-muted text-small">Active</div>
                                    </div>
                                ) : resp.data.status === 'CANCELLED' ? (
                                    <div className={`${styles['status-gws']}`}>
                                        <span className="status-suspended"></span>
                                        <div className="py-1 text-muted text-small">Cancelled</div>
                                    </div>
                                ) : resp.data.status === 'PENDING' ? (
                                    <div className={`${styles['status-gws']}`}>
                                        <span className="status-pending"></span>
                                        <div className="py-1 text-muted text-small">Pending</div>
                                    </div>
                                ) : (
                                    resp.data.status
                                ),
                                "Auto Renew": (resp.data.autoRenew ? "Yes" : "No"),
                                "Subscription Type": resp.data.subscriptionType,
                                "RRP (Inc GST)": `$${resp.data.rrpInclusiveGST}`,
                                "Reseller Price (Ex GST)": `$${resp.data.price}`,
                                "Start Date": resp.data.startDate,
                                "Renewal Date": resp.data.renewalDate,
                            }
                        );
                    setTimeout(() => {
                        setTableSubscriptionTable(f);         // Setting Table Data
                    }, 50);
                    let d = [];
                    ColumnNameSubscriptionTable.map((td) => {
                        if (td === "Quantity" || td === "RRP (Inc GST)" || td === "Reseller Price (Ex GST)") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                right: true,
                                allowOverflow: true,
                            })
                        }
                        else if (td === "Commitment Term (Billing Frequency)") {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">
                                    {row[`${td}`]}</div>,
                                left: true,
                                grow: 2,
                                allowOverflow: true,
                            })
                        }
                        else {
                            d.push({
                                id: `${td}`,
                                name: <div>{td}</div>,
                                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                                left: true,
                            })
                        }
                    })
                    setColumnsSubscriptionTable(d);     // Setting Column Data
                }
            })
            .catch(error => {
                setIsAutoRefreshEnabled(false);
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    const handleActionsClick = (id, rowPageNo) => {
        setIsActionInProgress(true);
        setIsAutoRefreshEnabled(false);
        switch (id) {
            case 'editquantity':
                setEditQuantityActionPopupModalOpen(true);
                break;
            case 'changestatus':
                setChangeStatusActionPopupModalOpen(true);
                break;
            case 'upgrade':
                setUpgradeActionPopupModalOpen(true);
                break;
            case 'renewal-settings':
                setRenewalSettingsActionPopupModalOpen(true);
                break;
            case 'purchase-add-ons':
                setPurchaseAddOnsActionPopupModalOpen(true);
                break;
            case 'trial-to-paid':
                setTrialToPaidActionPopupModalOpen(true);
                break;
            case 'migrate':
                setMigrationActionPopupModalOpen(true);
                break;
            default:
        }
    };

    // Function to call all dropdown values in Customer Section in Filter Box
    async function GetAllCustomers() {
        await api.get(`${GET_CUSTOMERS_LIST}?requestedResellerCode=${resellerId}`, config)
            .then(resp => {
                setCustomerData(resp.data);
                setCustomerNameFiltered(resp.data);

                for (let i in resp.data) {
                    if (resp.data[i].id === customerId) {
                        setCustomerNameDefaultValue(resp.data[i].name) // serviceName.push(resp.data[i].name);
                    }
                }

                for (let i in resp.data) {
                    if (!customerName.includes(resp.data[i].name)) {
                        customerName.push(resp.data[i].name);
                    }
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // function to call all additional details 
    async function GetAdditionalDetails() {
         await api.get(`${CSP_SERVICE_MANAGEMENT_ACCOUNT_DETAILS}?customerId=${customerId}&requestedResellerCode=${resellerId}`, config)
            .then(resp => {
                setAdditionalDetails(resp.data);
                if (resp.data.isProvision) {
                    setInitialSubscriptionTableCall(true);
                    setProvisionRefreshIconFlag(false);
                }
                else {
                    setInitialSubscriptionTableCall(false);
                    setProvisionRefreshIconFlag(true);
                }
            })
            .catch(error => {
                setIsAutoRefreshEnabled(false);
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                    setInitialSubscriptionTableCall(false);
                }
            });
    };

    const handleIncrease = (qty) => {
        if (editedQuantity <= maxQuantity) {
            setEditedQuantity(parseInt(qty) + 1);
            setIsEditingQuantity(true);
        } else {
            setEditedQuantity(parseInt(qty));
        }
    }

    const handleDecrease = (qty) => {
        if (editedQuantity >= minQuantity) {
            setEditedQuantity(parseInt(qty) - 1);
            setIsEditingQuantity(true);
        } else {
            setEditedQuantity(parseInt(qty));
        }
    }

    const clearExistingData = () => {
         setTable([]);
         setTableData({});
         setTotalRows(0);
    };

    // Update Edit Quantity
    async function UpdateEditQuantity() {
        setConfirmLoadingFlag(true);
        let requestBody = {
            "subscriptionResourceList": [{
                "subscriptionId": parseInt(subscriptionId),
                "quantity": parseInt(editedQuantity),
                "poNumber": poNumber,
            }]
        };

        await api.post(CSP_EDIT_QUANTITY_POST, requestBody, config)
            .then(resp => {
                if (resp.status = 200) {
                    setEditQuantityModalOpen(false);
                    setErrorFlag(false);
                    setConfirmLoadingFlag(false);
                    setToastMessage("Quantity has been Updated Successfully");
                    setToastSuccess(true);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 5000);
                    setEditingQuantityRowId();
                    setEditedQuantity(null);
                    setIsEditingQuantity(false);
                    setEditQuantityErrorModalOpen(false);
                    setPoNumber(null);
                    setActionCompletedRefreshSubscriptionTable(true);
                setIsEditingInProgress(false);
                if (!isFilterOrSearchActive) {
                    setIsAutoRefreshEnabled(true);
                }
                }
            })
            .catch(error => {
                setIsAutoRefreshEnabled(false);
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                setErrorFlag(true);
                setConfirmLoadingFlag(false);
                setErrorMessage(error.message);
            })
    }

    async function GetSubscriptionDetails() {
        setLoading(true);
        setSubscriptionId(editingQuantityRowId);
        await api.get(CSP_SUBSCRIPTION_DETAILS + editingQuantityRowId, config)
            .then(resp => {
                setLoading(false);
                setMaxQuantity(resp.data.maxQuantity);
                setMinQuantity(resp.data.minQuantity);
                let f = [];
                f.push
                    (
                        {
                            "Plan": resp.data.planName,
                            "Commitment Term (Billing Frequency)": resp.data.billingTerm,
                            "Unit Price": `$${resp.data.price}`,
                            "SQU ID":resp.data.planCode,
                            "Quantity": editedQuantity,
                            "Sub-Total Price": "$" + Math.round((((resp.data.price) * parseInt(editedQuantity)) + Number.EPSILON) * 100) / 100
                        }
                    );
                setTimeout(() => {
                    setDetailsTable(f);
                }, 50);
                let d = [];
                detailsColumn.map((td) => {
                    if (td === "Unit Price" || td === "Quantity" || td === "Sub-Total Price") {
                        d.push({
                            id: `${td}`,
                            name: <div className="text-wrap">{td}</div>,
                            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                            right: true,
                            grow: 1
                            })
                    }
                    else if (td === "Commitment Term (Billing Frequency)") {
                        d.push({
                            id: `${td}`,
                            name: <div>{td}</div>,
                            selector: row => <div className="text-wrap">
                                {row[`${td}`]}</div>,
                            left: true,
                            grow: 1.3,
                            allowOverflow: true,
                        })
                    }
                    else {
                        d.push({
                            id: `${td}`,
                            name: <div>{td}</div>,
                            selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                            left: true,
                            grow: 1.5,
                             })
                    }
                })
                setDetailsTableColumn(d);
            })
            .catch(error => {
                setIsAutoRefreshEnabled(false);
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Function to call all dropdown values in SubscriptionType Section in Filter Box
    async function GetAllSubscriptionType() {
        await api.get(CSP_SUBSCRIPTION_TYPE, config)
            .then(resp => {
                setSubscriptionTypeData(resp.data);
            })
            .catch(error => {
                setIsAutoRefreshEnabled(false);
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Function to call all dropdown values in status Section in Filter Box
    async function GetAllStatuses() {
        await api.get(CSP_STATUS_TYPE, config)
            .then(resp => {
                setStatusData(resp.data);
            })
            .catch(error => {
                setIsAutoRefreshEnabled(false);
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    // Function to call all dropdown values in Account Type Section in Filter Box
    async function GetAllBillingType() {
        await api.get(CSP_BILLING_TERMS_TYPE, config)
            .then(resp => {
                setBillingTypeData(resp.data);
            })
            .catch(error => {
                setIsAutoRefreshEnabled(false);
                if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                }
                else {
                }
            });
    };

    const refreshData = () => {
        GetAdditionalDetails();    
        for (let page of pagesWithPending) {
            if (loadedPages.has(page)) {
                GetTableData(page, pageSize, searchValueTable, status, sortCol, sortDir, billingTerm, planType, true);
            }
        }
    };

    useEffect(() => {
    // Determine if filter or search is active
    // const filterActive = status || billingTerm || planType;
    const filterActive = billingTerm || planType;
    const searchActive = searchValueTable && searchValueTable.length > 0;
    setIsFilterOrSearchActive(searchActive);
    }, [status, billingTerm, planType, searchValueTable]);

    // Check for autorefresh execution
    useEffect(() => {
      if (isAutoRefreshEnabled && initialSubscriptionTableCall && !isFilterOrSearchActive && !isActionInProgress && !isEditingInProgress && !isSorting && pagesWithPending.size > 0) {
        const intervalId = setInterval(() => {
            refreshData();
        }, autoRefreshInterval);

        return () => clearInterval(intervalId);
      }
    }, [isAutoRefreshEnabled, autoRefreshInterval, initialSubscriptionTableCall, isFilterOrSearchActive, isActionInProgress, isEditingInProgress, isSorting, pagesWithPending]);


    useEffect(() => {
        if (editQuantityModalOpen) {
            GetSubscriptionDetails();
        }
    }, [editQuantityModalOpen]);

    // Function to show overlay when hover on i icon in add member
    const renderTooltip = (props) => (
        <Tooltip {...props}>{props.infoText}</Tooltip>
    );

    let d = [];
    (isreadOnly ? ColumnNameReadOnly : ColumnName).map((td) => {
        if (td === "Actions") {
            d.push({
                id: `${td}`,
                name: <div className='d-none-mobile'>{td}</div>,
                selector: row => <div className="text-wrap d-none-mobile">{row[`${td}`]}</div>,
                center: true,
                allowOverflow: true,
            })
        }
        else if (td === "Info" || td === "Status") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                center: true,
                allowOverflow: true,
            })
        }
        else if (td === "Plan") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                allowOverflow: true,
                grow: 4,
            })
        }
        else if (td === "Subscription Type") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                grow: 3,
                allowOverflow: true,
            })
        }
        else if (td === "Quantity") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                cell: (row) => {
                    let isActive = (!(row.IsTrial) && row.Status && row.Status.props && row.Status.props.children[1].props.children) === 'Active';
                    return (isActive) ?
                        editingQuantityRowId == row.subscriptionId ? (
                            <div className={`d-flex flex-wrap justify-content-between my-2`}>
                                <div className="quantityEditor">
                                    <QuantityEditor
                                        editedQuantity={editedQuantity}
                                        editQuantityRef={editQuantityRef}
                                        setEditedQuantity={setEditedQuantity}
                                        handleIncrease={handleIncrease}
                                        handleDecrease={handleDecrease}
                                        setIsEditingQuantity={setIsEditingQuantity}
                                        maxQuantity={maxQuantity}
                                        minQuantity={minQuantity}
                                        updatedQuanity={row["Quantity"]}
                                    />

                                    {/* {isEditingQuantity ? (
                                        <div className='mx-1' onClick={() => handleEditQuantitySave(row)}>
                                            <span className="quantity-tick" />
                                        </div>
                                    ) : (
                                        <div className='mx-1'>
                                            <span className="tick-grey" />
                                        </div>
                                    )} */}

                                    {isEditingQuantity ? (
                                        editedQuantity <= maxQuantity && editedQuantity >= minQuantity ? (
                                            <div className='mx-1' onClick={() => handleEditQuantitySave(row)}>
                                                <span className="quantity-tick" />
                                            </div>
                                        ) : (
                                            <div className='mx-1'>
                                                <span className="tick-grey" />
                                            </div>
                                        )
                                    ) : (
                                        <div className='mx-1'>
                                            <span className="tick-grey" />
                                        </div>
                                    )}
                                    <div className='mx-1' onClick={handleEditQuantityClose}>
                                        <span className="quantity-cross">&nbsp;</span>
                                    </div>
                                </div>
                                <div className='text-disabled col-12'>
                                    <span className="text-small">Min {minQuantity}, </span>
                                    <span className="text-small">Max {maxQuantity}</span>
                                </div>
                            </div>
                        ) : (
                            <div>
                                {row["Quantity"]}<span onClick={() => handleEditQuantityClick(row)} className={isreadOnly ? 'd-none' : `quantity-active px-4 d-none-mobile ${styles['mobile-actions-hide']}`}></span>
                            </div>
                        ) : (
                            <div>
                                {row["Quantity"]}<span className={isreadOnly ? 'd-none' : `quantity-disable px-4 d-none-mobile ${styles['mobile-actions-hide']}`}></span>
                            </div>
                        )
                },
                left: true,
                allowOverflow: true,
                grow: 2.8,
            })
        }
        else if (td === "Commitment Term (Billing Frequency)") {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">
                    {row[`${td}`]}</div>,
                left: true,
                grow: 2,
                allowOverflow: true,
            })
        }
        else if (td === "Start Date") {
            d.push({
                id: `${td}`,
                name: 
                <div>
    {isDescStartDate === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { 
        setScrollToDivTop(true); 
        setSortDir("DESC"); 
        setIsDescStartDate(true); 
        setSortCol("sortingStartDate"); 
        setIsDescRenewalDate(null);
        setIsSorting(true);
        setIsAutoRefreshEnabled(false);
    }}></span></>}
    {isDescStartDate === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { 
        setScrollToDivTop(true); 
        setSortDir("ASC"); 
        setIsDescStartDate(false); 
        setSortCol("sortingStartDate");
        setIsSorting(true);
        setIsAutoRefreshEnabled(false);
    }}></span></>}
    {isDescStartDate === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { 
        setScrollToDivTop(true); 
        setSortDir("DESC"); 
        setIsDescStartDate(true); 
        setSortCol("sortingStartDate");
        setIsSorting(true);
        setIsAutoRefreshEnabled(false);
    }}></span></>}
</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                grow: 1.5
            })
        }
        else if (td === "Renewal Date") {
            d.push({
                id: `${td}`,
                name: <div>
    {isDescRenewalDate === null && <>{td}<span className="sort-icon-disabled sort-icon-sub-position mx-1" onClick={() => { 
        setScrollToDivTop(true); 
        setSortDir("DESC"); 
        setIsDescRenewalDate(true); 
        setSortCol("sortingRenewalDate"); 
        setIsDescStartDate(null);
        setIsSorting(true);
        setIsAutoRefreshEnabled(false);
    }}></span></>}
    {isDescRenewalDate === true && <>{td}<span className="sort-icon-asc sort-icon-sub-position mx-1" onClick={() => { 
        setScrollToDivTop(true); 
        setSortDir("ASC"); 
        setIsDescRenewalDate(false); 
        setSortCol("sortingRenewalDate");
        setIsSorting(true);
        setIsAutoRefreshEnabled(false);
    }}></span></>}
    {isDescRenewalDate === false && <>{td}<span className="sort-icon-desc sort-icon-sub-position mx-1" onClick={() => { 
        setScrollToDivTop(true); 
        setSortDir("DESC"); 
        setIsDescRenewalDate(true); 
        setSortCol("sortingRenewalDate");
        setIsSorting(true);
        setIsAutoRefreshEnabled(false);
    }}></span></>}
</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                grow: 1.7
            })
        }
        else {
            d.push({
                id: `${td}`,
                name: <div>{td}</div>,
                selector: row => <div className="text-wrap">{row[`${td}`]}</div>,
                left: true,
                grow: 1
            })
        }
    })

    // Three Dot Actions Functionality
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            className={styles['threeDotAnchorTag']}
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <span className={styles["threedots"]} />
        </a>
    ));

    // Get Table Data with Pagination and Filter and Search Functionality
    async function GetTableData(page, newPerPage, search, status, sortCol, sortDir, billingTerm, planType, isFilter= false) {
        setLoading(true);
        (search === undefined || search === null) ? search = "" : search = search;
        (status === undefined || status === null) ? status = "" : status = status;
        (billingTerm === undefined || billingTerm === null) ? billingTerm = "" : billingTerm = billingTerm;
        (planType === undefined || planType === null) ? planType = "" : planType = planType;
        isFilter = (isFilter === undefined || isFilter === null) ? false : isFilter;

        try {
        const resp = await api.get(`${GET_CSP_SUBSCRIPTION_TABLE}?pageNo=${page}&pageSize=${newPerPage}&searchText=${search}&status=${status}&customerId=${customerId}&sortCol=${sortCol}&sortDir=${sortDir}&billingTerm=${billingTerm}&subscriptionType=${planType}&requestedResellerCode=${resellerId}`, config)
                setLoading(false);
                setInfinityLoading(false);
                if (resp.status == 204) {
                    setPagesWithPending(new Set());
                    if(filterFlag){
                        isFilter = filterFlag
                        isFilter = search != "" ? !isFilter : isFilter; 
                    }
                    if (search === "" && ((status === "" && billingTerm === "" && planType === "") || (status.length === 0 && billingTerm.length === 0 && planType.length === 0) )) {
                        setShowFilterBox(false);
                        setEmptyUser(true);
                    }
                    else if ((status != "" || billingTerm != "" || planType != "") && isFilter) {
                        setEmptyUserFilters(true);
                    }
                    else if (search != "" && !isFilter) {
                        setEmptyUserSearch(true);
                    }
                }
                // handle success
                else {
                    const hasPending = resp.data.content.some(item => item.status.toUpperCase() === 'PENDING');
                    updatePendingPages(page, hasPending);
                    setHasPendingStatus(hasPending);
                    setLoadedPages(prev => new Set(prev).add(page));
                    resetErrorStates();
                    setTotalRows(resp.data.totalCount);
                    const formattedData = resp.data.content.map((td) => createTableRow(td, pageSize, pageNo));
                    const uniqueData = removeDuplicates(formattedData, 'subscriptionId');
                    updateTableData(page, uniqueData, newPerPage, isFilter);
                    setTotalRows(resp.data.totalCount);
                    setTable(prevTable => [...prevTable]);
                }
        }
        catch(error){
                    setLoading(false); 
                    setIsAutoRefreshEnabled(false);
                    if (error?.status == 401 || error?.response.status == 401) {
                    Logout();
                    }
                    else {
                    setServerErrorUser(true);
                    }
                }
    };

    function updatePendingPages(page, hasPending) {
        setPagesWithPending(prev => {
        const newSet = new Set(prev);
        if (hasPending) {
            newSet.add(page);
        } else {
            newSet.delete(page);
        }
        return newSet;
     });
    }

    function resetErrorStates() {
        setServerErrorUser(false);
        setEmptyUser(false);
        setEmptyUserSearch(false);
        setEmptyUserFilters(false);
    }

    function createTableRow(td, pageSize, pageNo) {
    return {
        "Plan": <>
            {td.planName}
            <div className={`py-1 ${styles['csp-vendor-id']}`}>{td.vendorSubscriptionId}</div>
        </>,
        "subscriptionId": td.subscriptionId,
        "Quantity": td.quantity,
        "Commitment Term (Billing Frequency)": td.billingTerm,
        "IsTrial": td.isTrial,
        "Start Date": td.startDate,
        "Renewal Date": td.renewalDate,
        "has Add On": td.hasAddOn,
        "Subscription Type": <>{td.planType}{!td.isNcePlan && <OverlayTrigger
            placement="top"
            overlay={<Tooltip className={`${styles['gws-tooltip']}`}>Migrate to NCE</Tooltip>}
        >
            <span className="infoIcon-support-table infoIcon-support-table-big ml-1"></span>
        </OverlayTrigger>}</>,
        "Status": getStatusComponent(td.status),
        "Info": <span
            className="infoIcon"
            onClick={() => {
                setIsSubscriptionDetailsPopupOpen(true);
                GetSubscriptionDetailsPopup(td.subscriptionId);
            }}
        >
            &nbsp;
        </span>,
        "Actions": getActionsComponent(td, pageSize, pageNo)
    };
    }

    function updateTableData(page, uniqueData, newPerPage, isFilter) {
    setTableData(prevData => ({
        ...prevData,
        [page]: uniqueData
    }));

    setTable(prevTable => {
        let updatedTable;
        if (!isFilter) {
            updatedTable = [...prevTable, ...uniqueData];
        } else {
            updatedTable = [...prevTable];
            const startIndex = (page - 1) * newPerPage;
            const endIndex = startIndex + uniqueData.length;
            updatedTable.splice(startIndex, endIndex - startIndex, ...uniqueData);
        }
        return removeDuplicates(updatedTable, 'subscriptionId');
    });
    }

    function getStatusComponent(status) {
    const statusMap = {
        'ACTIVE': { className: 'status-active', text: 'Active' },
        'SUSPENDED': { className: 'status-suspended', text: 'Suspended' },
        'PENDING': { className: 'status-pending', text: 'Pending' },
        'DELETED': { className: 'status-deleted', text: 'Deleted' },
        'DISABLED': { className: 'status-disabled', text: 'Disabled' },
        'EXPIRED': { className: 'status-expired', text: 'Expired' }
    };

    const statusInfo = statusMap[status] || { className: '', text: status };

    return (
        <div className={`${styles['status-csp']}`}>
            <span className={statusInfo.className}></span>
            <div className="py-1 text-muted text-small">{statusInfo.text}</div>
        </div>
    );
    }

    function getActionsComponent(td, pageSize, pageNo) {
    if (td.status === "PENDING" || td.status === "DELETED" || td.actions.length === 0) {
        return <span className={`${styles['threedots']} three-dots-icon-disabled`} />;
    }

    return (
        <Dropdown drop="start" onToggle={handleDropdownToggle}>
            <Dropdown.Toggle as={CustomToggle} />
            <Dropdown.Menu size="sm">
                {td.actions.map((items) => (
                    <Dropdown.Item key={items.id} onClick={() => handleActionClick(items, td, pageSize, pageNo)}>
                        {getActionIcon(items.id)} {items.value}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
    }

    function handleDropdownToggle(isOpen) {
    setIsAutoRefreshEnabled(!isOpen);
    }

    function handleActionClick(items, td, pageSize, pageNo) {
    GetActionsData(td.subscriptionId);
    handleActionsClick(items.id, Math.ceil((td.index + 1) / pageSize));
    setActionsSubscriptionId(td.subscriptionId);
    setActionsPlanName(td.planName);
    }

    function getActionIcon(id) {
    const iconMap = {
        'editquantity': 'Edit-Icon',
        'changestatus': 'change-status',
        'upgrade': 'upgrade',
        'downgrade': 'downgrade',
        'trial-to-paid': 'trial-to-paid'
    };

    const iconClass = iconMap[id] || 'renewal-settings';
    return <span className={iconClass}></span>;
    }

    // On search field value we trigger this function having react debugging after every 800 ms
    useEffect(() => {
    if (searchValueTable !== undefined && searchValueTable !== null) {
        const getData = setTimeout(() => {
            if (searchValueTable.length > 0) {
                // setIsSearchMode(false);
                setScrollToDivTop(true);
                setPageNo(1);
                setTable([]);
                setTableData({});
                GetTableData(1, pageSize, searchValueTable, status, sortCol, sortDir, billingTerm, planType);
                setIsAutoRefreshEnabled(false); 
                setPagesWithPending(new Set());
            } else {
                    setScrollToDivTop(true);
                    setPageNo(1);
                    setTable([]);
                    setTableData({});
                    // setIsSearchMode(false);
                    GetTableData(1, pageSize, searchValueTable, status, sortCol, sortDir, billingTerm, planType);
                    setIsAutoRefreshEnabled(true);
                    setPagesWithPending(new Set());
                }
        }, 800);
        return () => clearTimeout(getData);
    }
    }, [searchValueTable])


    // React Data Table Custom Theme
    createTheme('solarized', {
        text: {
            primary: '#000000',
        },
        background: {
            default: '#FFFFFF',
        },
        divider: {
            default: '#d7dadf',
        },
        striped: {
            default: '#f5f5f5',
        },
    });

    // React Data Table Custom Styles
    const customStyles = {
        tableWrapper: {
            style: {
                minHeight: '400px'
            },
        },

        //for adding background in row-first child
        headRow: {
            style: {
                backgroundColor: '#fafafa',
                zIndex: 3
            },
        },
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        cells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for data cells
                paddingRight: '1rem',
                // textAlign: 'center',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for head cells
                paddingRight: '1rem',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
                zIndex: 3
            },
        },
        expanderButton: {
            style: {
                '&:hover:enabled': {
                    cursor: 'pointer',
                },
                '&:disabled svg path': {
                    visibility: 'hidden'
                },
                svg: {
                    margin: 'auto',
                },
            },
        },
    };

    // React Data Table Small Custom Styles
    const customStylesTableSmall = {
        tableWrapper: {
            style: {
                minHeight: '100px'
            },
        },
        rows: {
            style: {
                minHeight: '60px', // override the row height
            },
        },
        //for adding background in row-first child
        headRow: {
            style: {
                backgroundColor: '#fafafa'
            },
        },
        cells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for data cells
                paddingRight: '1rem',
                fontSize: '0.9rem',
                fontWeight: '400',
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        headCells: {
            style: {
                paddingLeft: '1rem', // override the cell padding for head cells
                paddingRight: '1rem',
                paddingTop: '10px',
                paddingBottom: '10px',
                backgroundColor: '#fafafa',
                fontWeight: '500',
                fontSize: '0.875rem',
                color: '#6A6A6A !important',
                textTransform: 'capitalize',
            },
        },
        expanderButton: {
            style: {
                '&:hover:enabled': {
                    cursor: 'pointer',
                },
                '&:disabled svg path': {
                    visibility: 'hidden'
                },
                svg: {
                    margin: 'auto',
                },
            },
        },
    };
    // Function Triggers when Page Number is Changed by Customer
    const handlePageChange = (pageNo) => {
        GetTableData(pageNo, pageSize, searchValueTable, status, sortCol, sortDir, billingTerm, planType);
        setPageNo(pageNo);
    };
    // Function Triggers when Rows Per Page is Changed by Customer
    const handlePerRowsChange = async (newPerPage, page) => {
        setPageNo(page);
        setPageSize(newPerPage);
        GetTableData(page, newPerPage, searchValueTable, status, sortCol, sortDir, billingTerm, planType);
    };

    // Use Effect to get all the services in filter service dropdown
    useEffect(() => {
    if (customerId) {
        setEmptyUser(false);
        clearExistingData();
        GetAdditionalDetails();
        GetAllServices();
        GetAllCustomers();
    }
    }, [customerId]);

    // Refreshing Subscription Table on Action Completion
    useEffect(() => {
        if (actionCompletedRefreshSubscriptionTable) {
            GetAdditionalDetails();
            const actionedItemIndex = table.findIndex(item => item.subscriptionId == actionsSubscriptionId);
            const calculatedPageNo  = Math.floor(actionedItemIndex / pageSize) + 1;
            setActionPageNo(calculatedPageNo);
            GetTableData(calculatedPageNo , pageSize, searchValueTable, status, sortCol, sortDir, billingTerm, planType, true);
            setActionCompletedRefreshSubscriptionTable(false);
        }
    }, [actionCompletedRefreshSubscriptionTable])

    // Function to trigger getTable data function on change in sorting icons and sort field name,by default this function trigger initial request
    useEffect(() => {
    if (initialSubscriptionTableCall) {
        clearExistingData();
        setPageNo(1);
        if (filterApplied) {
            GetTableData(1, pageSize, searchValueTable, status, sortCol, sortDir, billingTerm, planType, false, true);
        } else {
            GetTableData(1, pageSize, searchValueTable, status, sortCol, sortDir, billingTerm, planType, false, false);
        }
        if (isSorting) {
            setIsSorting(false);
            if (!isFilterOrSearchActive && hasPendingStatus) {
                setIsAutoRefreshEnabled(true);
            }
        }
    }
    // else{
    //     GetTableData(1, pageSize, searchValueTable, status, sortCol, sortDir, billingTerm, planType, false, false);
    // }
    }, [isDescRenewalDate, isDescStartDate, initialSubscriptionTableCall]);

    // Renders when service ID Changes
    useEffect(() => {
        if (serviceId) {
            let url = "";
            let integrationCode = "";
            serviceData && serviceData.map((data) => {
                if (data.id == serviceId) {
                    integrationCode = data.integrationCode
                }
            })
            switch (integrationCode) {
                case "softlayer": // IBM Cloud
                    navigate("/management-console/ibm", { state: { customerId: customerId , resellerId: resellerId} });
                    break;

                case "microsoftazure": // Microsoft CSP - Azure
                    navigate("/management-console/azure", { state: { customerId: customerId , resellerId: resellerId} });
                    break;

                case "cloudcare": // Avast Cloud Care
                    navigate("/management-console/avast", { state: { customerId: customerId, resellerId: resellerId } });
                    break;

                case "acronis1": //  Acronis Cyber Cloud
                    navigate("/management-console/acronis", { state: { customerId: customerId ,resellerId: resellerId} });
                    break;

                case "googleworkspace": // Google Workspace
                    navigate("/management-console/gws", { state: { customerId: customerId,resellerId: resellerId } });
                    break;

                case "GCP":  // Google Cloud Platform
                    navigate("/management-console/gcp", { state: { customerId: customerId ,resellerId: resellerId} });
                    break;

                default: // Microsoft CSP
                    navigate("/management-console/csp", { state: { customerId: customerId,resellerId: resellerId } });
                    break;
            }
        }
    }, [serviceId]);

    //Scroll to top on opening of page
    useEffect(() => {
        window.scrollTo(0, 0);
        GetAllSubscriptionType();
        GetAllBillingType();
        GetAllStatuses();
    }, [])

    // Search Box Focus on Open
    useEffect(() => {
        searchInputRef.current && searchInputRef.current.focus();
    }, [searchIconFlag]);

    useEffect(() => {
        if (location.state?.subscriptionId) {
            GetActionsData(location.state?.subscriptionId);
            if (location.state?.isRenewalSettings === true) {
                setRenewalSettingsActionPopupModalOpen(true);
            }
            else {
                setChangeStatusActionPopupModalOpen(true);
            }
        }

    }, [location.state?.subscriptionId])

    const removeDuplicates = (array, key) => {
    return array.filter((item, index, self) =>
    index === self.findIndex((t) => t[key] === item[key])
     );
    };

    // Function to handle the lazy loading calls invoked from the Lazy Table
    const handleLazyCalls = async () => {
    setInfinityLoading(true);
    const nextPage = pageNo + 1;
    if (!tableData[nextPage]) {
        await GetTableData(nextPage, pageSize, searchValueTable, status, sortCol, sortDir, billingTerm, planType);
    } else {
        setTable(prevTable => {
            const newTable = [...prevTable, ...tableData[nextPage]];
            return removeDuplicates(newTable, 'subscriptionId');
        });
    }
    setPageNo(nextPage);
    setInfinityLoading(false);
    };

    return (
        <>
            <Toast toastSetUseState={setToastSuccess} toastCheckUseState={toastSuccess} toastType="success" toastIconColor="#1D874C" toastMessage={toastMessage} />
            <Toast toastSetUseState={setToastError} toastCheckUseState={toastError} toastType="danger" toastIconColor="#9C3B35" toastMessage={toastMessage} />

            <div className='main-content'>
                <div className='container-fluid'>

                    {/* Edit Quantity Modal */}
                    <Popup
                        open={editQuantityModalOpen}
                        onClose={() => {
                            setEditQuantityModalOpen(false);

                            setSubscriptionTableAPIData(null);
                            setErrorFlag(false);
                            setErrorMessage(null);
                        }}
                        className={!errorFlag ? "custom-modal custom-modal-xxl" : "custom-modal"}
                        closeOnDocumentClick={!confirmLoadingFlag}
                        modal
                        nested
                    >
                        {(close) => (
                            <div className="modal">

                                <div className="header">
                                    <h2>{errorFlag ? "Order Failed" : "Order Confirmation - Edit Quantity"}</h2>
                                    <XLg
                                        size={24}
                                        className="cpointer close-icon-popup"
                                        onClick={() => { if (!confirmLoadingFlag) { close(); } }}
                                    />
                                </div>
                                {errorFlag &&
                                    <>
                                        <Row className="pb-3 pt-5">
                                            <Col md={12} className="text-center my-4">
                                                <img src={failureImg} alt="Edit Quantity Failure" />
                                            </Col>
                                        </Row>
                                        <Container fluid>
                                            <p className='text-center text-strong'>{errorMessage}</p>
                                            <div className="btn-wrapper text-center mb-6">
                                                <Button
                                                    className="px-4 mx-2"
                                                    onClick={() => { setEditQuantityModalOpen(false); }}>
                                                    Cancel
                                                </Button>

                                                <Button
                                                    className="px-4 mx-2"
                                                    onClick={() => { setErrorFlag(false); }}>
                                                    Retry
                                                </Button>
                                            </div>
                                           </Container>
                                    </>
                                }
                                {!errorFlag &&
                                    <Container fluid>
                                        <Row className="pb-3 pt-5">
                                            <Col lg={8} md={6}>
                                                <div className="mb-3 text-regular text-bold-strong">Customer Name: <span className=" text-regular">{customerNameDefaultValue}</span></div>
                                            </Col>
                                            <Col lg={4} md={6}>
                                                <div className="mb-3 text-regular text-bold-strong">Cloud Service: <span className=" text-regular">Microsoft CSP</span></div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {detailsTable &&
                                                    <DataTable
                                                        columns={detailsTableColumn}
                                                        data={detailsTable}
                                                        theme="solarized"
                                                        customStyles={customStylesTableSmall}
                                                        persistTableHead={false}
                                                        progressComponent={<div className={styles["loading-subscriptions-order-confirmation"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Subscriptions...</p></div>}
                                                        width="100%"
                                                        fixedHeaderScrollHeight="60vh"
                                                        progressPending={loading}
                                                        fixedHeader
                                                    />
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="py-3">
                                            <Col>

                                                <Form.Label className="w-auto" htmlFor="inputPONumber">PO Number:</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="w-auto d-inline ml-3"
                                                    id="inputPONumber"
                                                    placeholder='Add PO Number'
                                                    aria-describedby="inputPONumber"
                                                    maxlength="50"
                                                    value={poNumber}
                                                    onChange={(e) => {
                                                        alphanumericRegex.test(e.target.value)
                                                            ? setPoNumber(e.target.value)
                                                            : console.log("")
                                                    }}
                                                />
                                                <Form.Text id="poNumber" />

                                            </Col>
                                        </Row>
                                        <Row className="mt-2 mb-4">
                                            <Col md={6} className='notes-confirmation'>

                                                <Form.Check // prettier-ignore
                                                    type="checkbox"
                                                    id="termsCheckBox"
                                                    label=""
                                                    disabled
                                                    checked={isTermsChecked}
                                                    className="d-inline-block w-auto"
                                                />
                                                <Form.Label className="d-inline-block  w-auto mb-0">
                                                    I agree to the <a href="/terms-and-conditions" target='_blank' onClick={() => setIsTermsChecked(true)}>terms & conditions</a> of Synnex cloud platform
                                                </Form.Label>

                                            </Col>
                                            <Col md={6}>
                                                <div className="btn-wrapper text-right d-md-block d-none mb-3">
                                                    {!confirmLoadingFlag && isTermsChecked && <>
                                                        <Button
                                                            className="px-4 mx-2"
                                                            onClick={() => { setEditQuantityModalOpen(false); }}>Cancel</Button>
                                                        <Button
                                                            className="px-4 mx-2"
                                                            onClick={UpdateEditQuantity}>
                                                            Confirm
                                                        </Button>
                                                    </>
                                                    }
                                                    {!confirmLoadingFlag && !isTermsChecked &&
                                                        <>
                                                            <Button
                                                                className="px-4 mx-2"
                                                                onClick={() => { setEditQuantityModalOpen(false); }}>Cancel
                                                            </Button>
                                                            <Button
                                                                variant="light"
                                                                className="px-4 mx-2"
                                                                disabled
                                                            >
                                                                Confirm
                                                            </Button>
                                                        </>
                                                    }
                                                    {confirmLoadingFlag &&
                                                        <>
                                                            <Button
                                                                variant="light"
                                                                disabled
                                                                className="px-4 mx-2 btn-border-light w-md-auto col-xs-12"
                                                            >Cancel
                                                            </Button>
                                                            <Button
                                                                disabled
                                                                className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                                variant="light"
                                                            >
                                                                Processing . . .
                                                            </Button>
                                                        </>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>}
                            </div>

                        )}
                    </Popup>
                    {/* cloud portal details */}
                    <Popup
                        open={modalOpen}
                        onClose={() => { setModalOpen(false); setTabName("Cloud Portal Details"); }}
                        className="custom-modal custom-modal-xxl"
                        modal
                        nested
                    >
                        {(close) => (
                            <div className="modal">
                                <div className="header">
                                    <h2>Cloud Portal Details</h2>
                                    <XLg
                                        size={24}
                                        className="cpointer close-icon-popup"
                                        onClick={close}
                                    />
                                </div>
                                <div className='mx-4 my-3'>
                                    <Tab.Container activeKey={tabName}>
                                        <Row>
                                            <Col sm={12}>
                                                <Nav variant="pills">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="Cloud Portal Details" className='addons-core-gws-manage  pl-6 pr-6 pt-3 pb-3'
                                                            onClick={() => { setTabName("Cloud Portal Details") }}
                                                        >
                                                            Cloud Portal Details
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="GDAP Relationships" className='addons-core-gws-manage pl-6 pr-6 pt-3 pb-3'
                                                            onClick={() => { setTabName("GDAP Relationships") }}
                                                        >
                                                            GDAP Relationships
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    {!isreadOnly && <Nav.Item>
                                                        <Nav.Link eventKey="Create GDAP" className='addons-core-gws-manage  pl-6 pr-6 pt-3 pb-3 d-none-mobile'
                                                            onClick={() => { setTabName("Create GDAP") }}
                                                        >
                                                            Create GDAP
                                                        </Nav.Link>
                                                    </Nav.Item>}
                                                </Nav>
                                            </Col>
                                            <Col lg={12}>
                                                <Tab.Content>
                                                    {/* Cloud Portal Details */}
                                                    <Tab.Pane eventKey="Cloud Portal Details">
                                                        <div className={`mt-4 mx-1 ${styles['gdap-height']}`}>
                                                            {additionalDetails && <Row>
                                                                <Col md={12} className="mb-2">
                                                                    <label className="text-medium">Cloud Service</label>
                                                                    <p><strong>Microsoft CSP</strong></p>
                                                                </Col>
                                                                <Col md={12} className="mb-2">
                                                                    <label className="text-medium">Customer</label>
                                                                    <p><strong>{additionalDetails.customerName}</strong></p>
                                                                </Col>
                                                                <Col md={12} className="mb-2">
                                                                    <label className="text-medium">User Name</label>
                                                                    <p><strong>{additionalDetails.userName}</strong></p>
                                                                </Col>
                                                                <Col md={12} className="mb-2">
                                                                    <label className="text-medium">Password</label>
                                                                    <p><strong>{additionalDetails.password}</strong></p>
                                                                </Col>
                                                                <Col md={12} className="mb-2">
                                                                    <label className="text-medium">Start Date</label>
                                                                    <p><strong>{additionalDetails.startDate}</strong></p>
                                                                </Col>
                                                                <Col md={12} className="mb-2">
                                                                    <label className="text-medium">Portal</label>
                                                                    <p className='service-management-acronis-portal-link'>
                                                                        <a href={additionalDetails.serviceUrl} target='_blank' rel="noreferrer">
                                                                            {additionalDetails.serviceUrl}<span className='external-link cpointer ml-1'></span>
                                                                        </a>
                                                                    </p>
                                                                </Col>
                                                                <Col md={12} className="mb-2">
                                                                    <label className="text-medium">Domain Name</label>
                                                                    <p className='d-flex align-items-center'>
                                                                        <span className="text-strong long-text">{additionalDetails.userName}</span>
                                                                        <span className="clicktocopy">
                                                                            <span
                                                                                type="button"
                                                                                class="copy cpointer ml-2"
                                                                                onClick={() => copyToClipboard(additionalDetails.userName)}
                                                                            >
                                                                                &nbsp;
                                                                            </span>
                                                                            <span className="copystatus">{copied}</span>
                                                                        </span>
                                                                    </p>
                                                                </Col>
                                                            </Row>}
                                                        </div>
                                                    </Tab.Pane>
                                                    {/* GDAP Relationships */}
                                                    <Tab.Pane eventKey="GDAP Relationships">
                                                        <GDAPRelationship vendorAccountId={additionalDetails?.vendorAccountId} resellerId={resellerId} tabName={tabName} Logout={Logout} />
                                                    </Tab.Pane>

                                                    {/* Create GDAP Tab */}
                                                    <Tab.Pane eventKey="Create GDAP">
                                                        <CreateGDAP vendorAccountId={additionalDetails?.vendorAccountId} toastError={toastError} toastMessage={toastMessage} Logout={Logout}
                                                            toastSuccess={toastSuccess} setToastError={setToastError} setToastSuccess={setToastSuccess} setToastMessage={setToastMessage} setTabName={setTabName} />
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </div>
                            </div>
                        )}
                    </Popup>
                    {/* Subscription Details info Popup */}
                    <Popup
                        className="custom-modal"
                        open={isSubscriptionDetailsPopupOpen}
                        onClose={() => setIsSubscriptionDetailsPopupOpen(false)}
                        modal
                        nested
                    >
                        {(close) => (
                            <div className="modal">
                                {!subscriptionDetailIsLoadingFlag &&
                                    <>
                                        <div className="header">
                                            <h2>Subscription Details</h2>
                                            <XLg
                                                size={18}
                                                className="cpointer text-strong"
                                                onClick={close}
                                                color="#6A6A6A"
                                            />
                                        </div>
                                        <Container>
                                            <Row>
                                                <Col className="mb-3">
                                                    <label className="text-medium">
                                                        Plan
                                                    </label>
                                                    <p>
                                                        <strong>
                                                            {subscriptionDetailsPlanName && subscriptionDetailsPlanName}
                                                        </strong>
                                                    </p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mb-3">
                                                    <label className="text-medium">
                                                        Vendor Subscription Id
                                                    </label>
                                                    <p className="mb-0 text-medium">
                                                        <strong>
                                                            {subscriptionDetailsVendorSubscriptionId && subscriptionDetailsVendorSubscriptionId.toUpperCase()}
                                                        </strong>
                                                    </p>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col className="mb-3">
                                                    <label className="text-medium">
                                                        Licence Auto Renewal
                                                    </label>
                                                    <p className="text-medium">
                                                        <strong>
                                                            {subscriptionDetailsLicenceAutoRenewal && subscriptionDetailsLicenceAutoRenewal ? "Yes" : "No"}
                                                        </strong>
                                                    </p>
                                                </Col>
                                            </Row>
                                            <Row className="mb-0">
                                                <Col md={6}>
                                                    <label className="text-medium">
                                                        Subscription Start Date
                                                    </label>
                                                    <p className="text-medium">
                                                        <strong>
                                                            {subscriptionDetailsStartDate && subscriptionDetailsStartDate}
                                                        </strong>
                                                    </p>
                                                </Col>
                                                <Col md={6}>
                                                    <label className="text-medium">
                                                        Subscription End Date
                                                    </label>
                                                    <p className="text-medium">
                                                        <strong>
                                                            {subscriptionDetailsEndDate && subscriptionDetailsEndDate}
                                                        </strong>
                                                    </p>
                                                </Col>
                                                {subscriptionDetailsIsTrial && (
                                                    <Col lg={6} md={6} className="mb-3">
                                                        <label className="text-medium">
                                                            Is Trial
                                                        </label>
                                                        <p className="text-medium mb-0">
                                                            <strong>
                                                                {subscriptionDetailsIsTrial ? "Yes" : "No"}
                                                            </strong>
                                                        </p>
                                                    </Col>
                                                )}
                                                {subscriptionDetailsIsTrial && (
                                                    <Col lg={6} md={6} className="mb-3">
                                                        <label className="text-medium mb-0">
                                                            Trial End Date
                                                        </label>
                                                        <p className="text-medium">
                                                            <strong>
                                                                {subscriptionDetailsEndDate}
                                                            </strong>
                                                        </p>
                                                    </Col>
                                                )}
                                            </Row>
                                            <Row>
                                            </Row>
                                        </Container>
                                    </>
                                }
                                {
                                    subscriptionDetailIsLoadingFlag &&
                                    <>
                                        <div className="header">
                                            <h2 className="mb-0">Subscription Details</h2>
                                            <XLg
                                                size={18}
                                                className="cpointer text-strong"
                                                onClick={close}
                                                color="#6A6A6A"
                                            />
                                        </div>
                                        <Container>
                                            <div className="empty-subscription-detail">
                                                <img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Subscription Details...</p>
                                            </div>
                                        </Container>
                                    </>
                                }
                            </div>
                        )}
                    </Popup>
                    <Row>
                        <Col md={6} className="justify-content-between d-flex align-items-center">
                            <label className="cpointer" onClick={() => navigate('/management-console', { state: { resellerId: resellerId } })}>
                                <span className="back">&nbsp;</span>&nbsp;<strong><span className={`${styles['service-management-heading']}`}>Microsoft CSP - Service Management</span></strong>
                            </label>
                        </Col>
                        <Col md={6} className="text-right service-logo">
                            <img className={`${styles['csp-service-management-logo']}`} src={serviceLogo} />
                        </Col>
                    </Row>
                    <div className='service-management-csp-header mt-5'>
                        <Row className='mb-3'>
                            <Col md={3} className='mb-3'>
                                <SearchDropdown
                                    dropdownLabel="Cloud Service"
                                    formGroupId="serviceNameId"
                                    placeholder="Enter Service Name"
                                    selectDefaultValue={serviceNameDefaultValue}
                                    setSelectDefaultValue={setServiceNameDefaultValue}
                                    selectOptions={serviceData}
                                    selectOptionsFiltered={serviceNameFiltered}
                                    setSelectOptionsFiltered={setServiceNameFiltered}
                                    selectSearchValue={serviceNameSearchValue}
                                    setSelectSearchValue={setServiceNameSearchValue}
                                    updatedOptions={updatedServiceName}
                                    setOptionId={setServiceId}
                                    setInitialSubscriptionTableCall={setInitialSubscriptionTableCall}
                                />
                            </Col>
                            <Col md={3} className='mb-3'>
                                <SearchDropdown
                                    dropdownLabel="Customer Name"
                                    formGroupId="customerNameId"
                                    placeholder="Enter Customer Name"
                                    selectDefaultValue={customerNameDefaultValue}
                                    setSelectDefaultValue={setCustomerNameDefaultValue}
                                    setOptionId={setCustomerId}
                                    selectOptions={customerData}
                                    selectOptionsFiltered={customerNameFiltered}
                                    setSelectOptionsFiltered={setCustomerNameFiltered}
                                    selectSearchValue={customerNameSearchValue}
                                    setSelectSearchValue={setCustomerNameSearchValue}
                                    updatedOptions={updatedCustomerName}
                                    setInitialSubscriptionTableCall={setInitialSubscriptionTableCall}
                                />
                            </Col>
                            <Col md={6} className='text-right'>
                                <div class="d-flex justify-content-between justify-content-md-end align-items-center h-100">
                                    <label class="pt-0 label-sm mr-3">Subscriptions
                                        <span class="circle-bg label-sm">
                                            <strong class="label-sm">
                                                {additionalDetails ? additionalDetails.subscriptionLicenseCount : 0}
                                            </strong>
                                        </span>
                                    </label>
                                    <label class="pt-0 label-sm mr-3">Add-Ons
                                        <span class="circle-bg label-sm">
                                            <strong class="label-sm">
                                                {additionalDetails ? additionalDetails.addonsLicenseCount : 0}
                                            </strong>
                                        </span>
                                    </label>
                                    {initialSubscriptionTableCall && <Button className="ml-3 btn-border-light w-md-auto col-xs-12" variant="light" onClick={() => setModalOpen(true)}>Cloud Portal Details</Button>}
                                    {!initialSubscriptionTableCall && <Button className="ml-3 btn-border-light w-md-auto col-xs-12" variant="secondary" disabled >Cloud Portal Details</Button>}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="service-management-csp-body user-management-console-main-div mb-5">
                        <div className="mgmt-console-table-row">
                            <div className="my-3 d-flex">
                                <Col className={`p-0 ${styles['user-management-table']}`}>
                                    <div className={`${styles['user-management-table-inner']} ${styles['table-details']} details-box`}>
                                        <div className="my-0 table-heading align-items-center row">
                                            <Col className={`${searchIconFlag ? "hide-in-mobile" : ""}`}>
                                            <h5 className="py-1 pl-1 mb-0 mobile-padding-search-open subscription-heading-table-header d-flex align-items-center">
                                            Subscriptions {!emptyUser && !emptyUserFilters && !emptyUserSearch && !loading && initialSubscriptionTableCall && <span className='pl-1 text-muted text-large'>({totalRows})</span>}
                                            {!loading && !provisionRefreshIconFlag && initialSubscriptionTableCall && 
                                            <span className='refresh ml-1' onClick={() => {
                                            setScrollToDivTop(true);
                                            clearExistingData();
                                            GetAdditionalDetails();
                                            setPageNo(1);
                                            GetTableData(1, pageSize, searchValueTable, status, sortCol, sortDir, billingTerm, planType, true);
                                            }}></span>
                                            }
                                            </h5>
                                            </Col>
                                           {((!resellerId || resellerId?.length==0) && !isCloudAdmin) && <Col className="my-1 d-md-none d-block text-right mr-1">
                                                <Button onClick={() => navigate("/cloud-provision", {
                                                    state: { customerId: customerId, serviceId: serviceId, isManagementConsoleNavigation: true }
                                                })}>Buy Subscription</Button>
                                            </Col>}

                                            {/* Filter and Search Box for Desktop and Tablet */}
                                            <Col md={8} className="d-none d-md-block">
                                                <Row className="justify-content-end align-items-center mobile-padding-search-open-sub">
                                                    {!serverErrorUser && !emptyUser && initialSubscriptionTableCall &&
                                                        <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative pr-4">
                                                            {!searchIconFlag &&
                                                                <span className="mx-3">&nbsp;</span>
                                                            }
                                                            {!searchIconFlag && !emptyUserFilters &&
                                                                <span className="search mx-3 cpointer" onClick={() => { setSearchIconFlag(!searchIconFlag); }}>&nbsp;</span>
                                                            }
                                                            {searchIconFlag && !emptyUserFilters &&
                                                                <InputGroup className="search-input-box search-input-box-xl">
                                                                    <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                        <span className="searchgrey cpointer"
                                                                        >
                                                                            &nbsp;
                                                                        </span>
                                                                    </InputGroup.Text>
                                                                    <Form.Control
                                                                        placeholder="Search Plan Name"
                                                                        aria-label="Search Plan Name"
                                                                        aria-describedby="basic-addon2"
                                                                        className="search-input-box-input"
                                                                        value={searchValueTable}
                                                                        onChange={(e) => {
                                                                        setSearchValueTable(e.target.value);
                                                                        if (e.target.value && e.target.value.length > 0) {
                                                                        setIsAutoRefreshEnabled(false);
                                                                        }
                                                                        }}
                                                                        ref={searchInputRef}
                                                                    />
                                                                    <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                        onClick={() => {
                                                                            setSearchValueTable("");
                                                                            setSearchIconFlag(!searchIconFlag);
                                                                        }}>
                                                                        <span className="closebtn">
                                                                            &nbsp;
                                                                        </span>
                                                                    </InputGroup.Text>
                                                                </InputGroup>
                                                            }
                                                            {!filterFlag && !emptyUserSearch && <span 
                                                                className="filter-black cpointer" 
                                                                id="filterblock" 
                                                                onClick={() => {
                                                            setShowFilterBox(!showFilterBox);
                                                            }}></span>}
                                                            {filterFlag && !emptyUserSearch && initialSubscriptionTableCall && <span className="filter-black-active cpointer" onClick={() => { setShowFilterBox(!showFilterBox) }}></span>}
                                                            {((!resellerId || resellerId?.length==0) && !isCloudAdmin) &&<Button className="ml-3 px-4" onClick={() => navigate("/cloud-provision", {
                                                                state: { customerId: customerId, serviceId: serviceId, isManagementConsoleNavigation: true }
                                                            })}>Buy Subscription</Button>}
                                                        </Col>}
                                                </Row>
                                            </Col>

                                            {/* Filter and Search Box for Mobile */}
                                            <Col className="d-md-none d-block mt-2 px-3" md={12}>
                                                <Row className="justify-content-end align-items-center mobile-padding-search-open-sub">
                                                    {!serverErrorUser && !emptyUser && initialSubscriptionTableCall && <Col className="d-flex align-items-center justify-items-center justify-content-end position-relative">

                                                        <InputGroup className="search-input-box">
                                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn">
                                                                <span className="searchgrey cpointer"
                                                                >
                                                                    &nbsp;
                                                                </span>
                                                            </InputGroup.Text>
                                                            <Form.Control
                                                                placeholder="Search Plan Name"
                                                                aria-label="Search Plan Name"
                                                                aria-describedby="basic-addon2"
                                                                className="search-input-box-input"
                                                                value={searchValueTable}
                                                                onChange={(e) => setSearchValueTable(e.target.value)}
                                                                ref={searchInputRef}
                                                            />
                                                            <InputGroup.Text id="basic-addon2" className="search-input-box-btn search-input-box-close"
                                                                onClick={() => {
                                                                    setSearchValueTable("");
                                                                    setSearchIconFlag(!searchIconFlag);
                                                                }}>
                                                                <span className="closebtn">
                                                                    &nbsp;
                                                                </span>
                                                            </InputGroup.Text>
                                                        </InputGroup>

                                                        {!filterFlag && !emptyUserSearch && <span 
                                                            className="filter-black cpointer" 
                                                            id="filterblock" 
                                                            onClick={() => {
                                                            setShowFilterBox(!showFilterBox);
                                                            }}
                                                        ></span>}
                                                        {filterFlag && !emptyUserSearch && initialSubscriptionTableCall && <span className="filter-black-active cpointer" 
                                                        onClick={() => {
                                                            setShowFilterBox(!showFilterBox);
                                                        }}
                                                        ></span>}
                                                    </Col>}
                                                </Row>
                                            </Col>
                                        </div>
                                        {
                                            !emptyUserSearch && showFilterBox &&
                                            <CSPFilterBox GetTableData={GetTableData} pageNo={pageNo} setPageNo={setPageNo}
                                                pageSize={pageSize} searchValueTable={searchValueTable} sortCol={sortCol} sortDir={sortDir}
                                                setShowFilterBox={setShowFilterBox} setFilterFlag={setFilterFlag}
                                                status={status} setStatus={setStatus} billingTypeData={billingTypeData} statusData={statusData} subscriptionTypeData={subscriptionTypeData}
                                                billingType={billingTerm} setBillingType={setBillingTerm} subscriptionType={planType} setSubscriptionType={setPlanType} setScrollToDivTop={setScrollToDivTop} setFilterApplied={setFilterApplied}
                                                setIsAutoRefreshEnabled={setIsAutoRefreshEnabled} setIsFilterOrSearchActive={setIsFilterOrSearchActive} clearExistingData={clearExistingData}
                                                setPagesWithPending={setPagesWithPending}
                                                setLoadedPages={setLoadedPages} setEmptyUserFilters={setEmptyUserFilters}
                                            />
                                        }
                                        {
                                            upgradeActionPopupModalOpen &&
                                            <CSPUpgrade subscriptionTableAPIData={subscriptionTableAPIData} subscriptionId={actionsSubscriptionId}
                                                upgradeActionPopupModalOpen={upgradeActionPopupModalOpen} setUpgradeActionPopupModalOpen={setUpgradeActionPopupModalOpen}
                                                columnsSubscriptionTable={columnsSubscriptionTable} tableSubscriptionTable={tableSubscriptionTable} setActionCompletedRefreshSubscriptionTable={setActionCompletedRefreshSubscriptionTable}
                                                GetTableData={GetTableData} setToastSuccess={setToastSuccess} setToastError={setToastError} setToastMessage={setToastMessage} Logout={Logout} setIsActionInProgress={setIsActionInProgress} setIsAutoRefreshEnabled={setIsAutoRefreshEnabled}
                                            />
                                        }
                                        {editQuantityActionPopupModalOpen &&
                                            <CSPEditQuantity editQuantityActionPopupModalOpen={editQuantityActionPopupModalOpen} setEditQuantityActionPopupModalOpen={setEditQuantityActionPopupModalOpen} setActionCompletedRefreshSubscriptionTable={setActionCompletedRefreshSubscriptionTable} setSubscriptionTableAPIData={setSubscriptionTableAPIData}
                                                subscriptionTableAPIData={subscriptionTableAPIData} subscriptionId={actionsSubscriptionId} columnsSubscriptionTable={columnsSubscriptionTable} setColumnsSubscriptionTable={setColumnsSubscriptionTable} tableSubscriptionTable={tableSubscriptionTable} Logout={Logout}
                                                setTableSubscriptionTable={setTableSubscriptionTable} minQuantity={minQuantity} maxQuantity={maxQuantity} editQuantityActionsValue={editQuantityActionsValue} setEditQuantityActionsValue={setEditQuantityActionsValue} newQuantity={newQuantity} setNewQuantity={setNewQuantity} setToastSuccess={setToastSuccess} setToastError={setToastError} setToastMessage={setToastMessage} setIsActionInProgress={setIsActionInProgress} setIsAutoRefreshEnabled={setIsAutoRefreshEnabled}
                                            />
                                        }
                                        {
                                            renewalSettingsActionPopupModalOpen &&
                                            <CSPRenewalSettings subscriptionTableAPIData={subscriptionTableAPIData} subscriptionId={actionsSubscriptionId} planName={actionsPlanName} setSubscriptionTableAPIData={setSubscriptionTableAPIData}
                                                renewalSettingsActionPopupModalOpen={renewalSettingsActionPopupModalOpen} setRenewalSettingsActionPopupModalOpen={setRenewalSettingsActionPopupModalOpen} Logout={Logout}
                                                columnsSubscriptionTable={columnsSubscriptionTable} tableSubscriptionTable={tableSubscriptionTable} setActionCompletedRefreshSubscriptionTable={setActionCompletedRefreshSubscriptionTable}
                                                GetTableData={GetTableData} setToastSuccess={setToastSuccess} setToastError={setToastError} setToastMessage={setToastMessage} isNcePlan={isNcePlan} setIsNcePlan={setIsNcePlan} setIsActionInProgress={setIsActionInProgress} setIsAutoRefreshEnabled={setIsAutoRefreshEnabled}
                                            />
                                        }
                                        {
                                            changeStatusActionPopupModalOpen &&
                                            <CSPChangeStatus subscriptionTableAPIData={subscriptionTableAPIData} setSubscriptionTableAPIData={setSubscriptionTableAPIData} subscriptionId={actionsSubscriptionId} planName={actionsPlanName}
                                                changeStatusActionPopupModalOpen={changeStatusActionPopupModalOpen} setChangeStatusActionPopupModalOpen={setChangeStatusActionPopupModalOpen} Logout={Logout}
                                                columnsSubscriptionTable={columnsSubscriptionTable} tableSubscriptionTable={tableSubscriptionTable} setActionCompletedRefreshSubscriptionTable={setActionCompletedRefreshSubscriptionTable}
                                                GetTableData={GetTableData} setToastSuccess={setToastSuccess} setToastError={setToastError} setToastMessage={setToastMessage} isNcePlan={isNcePlan} setIsNcePlan={setIsNcePlan} setIsActionInProgress={setIsActionInProgress} setIsAutoRefreshEnabled={setIsAutoRefreshEnabled}
                                            />
                                        }
                                        {
                                            trialToPaidActionPopupModalOpen &&
                                            <CSPTrialToPaid subscriptionTableAPIData={subscriptionTableAPIData} subscriptionId={actionsSubscriptionId} planName={actionsPlanName} Logout={Logout}
                                                trialToPaidActionPopupModalOpen={trialToPaidActionPopupModalOpen} setTrialToPaidActionPopupModalOpen={setTrialToPaidActionPopupModalOpen}
                                                columnsSubscriptionTable={columnsSubscriptionTable} tableSubscriptionTable={tableSubscriptionTable} setActionCompletedRefreshSubscriptionTable={setActionCompletedRefreshSubscriptionTable}
                                                GetTableData={GetTableData} setToastSuccess={setToastSuccess} setToastError={setToastError} setToastMessage={setToastMessage} setIsActionInProgress={setIsActionInProgress} setIsAutoRefreshEnabled={setIsAutoRefreshEnabled}
                                            />
                                        }
                                        {
                                            purchaseAddOnsActionPopupModalOpen &&
                                            <CSPPurchaseAddOns purchaseAddOnsActionPopupModalOpen={purchaseAddOnsActionPopupModalOpen} setPurchaseAddOnsActionPopupModalOpen={setPurchaseAddOnsActionPopupModalOpen} Logout={Logout}
                                                subscriptionTableAPIData={subscriptionTableAPIData} subscriptionId={actionsSubscriptionId} columnsSubscriptionTable={columnsSubscriptionTable} setColumnsSubscriptionTable={setColumnsSubscriptionTable} tableSubscriptionTable={tableSubscriptionTable}
                                                setTableSubscriptionTable={setTableSubscriptionTable} minQuantity={minQuantity} maxQuantity={maxQuantity} editQuantityActionsValue={editQuantityActionsValue} setEditQuantityActionsValue={setEditQuantityActionsValue} newQuantity={newQuantity} setNewQuantity={setNewQuantity} setToastSuccess={setToastSuccess} setToastError={setToastError} setToastMessage={setToastMessage} setActionCompletedRefreshSubscriptionTable={setActionCompletedRefreshSubscriptionTable}
                                                customerId={customerId} setIsActionInProgress={setIsActionInProgress} setIsAutoRefreshEnabled={setIsAutoRefreshEnabled}
                                            />
                                        }
                                        {
                                            migrationActionPopupModalOpen &&
                                            <CSPMigrate subscriptionTableAPIData={subscriptionTableAPIData} setSubscriptionTableAPIData={setSubscriptionTableAPIData} migrationActionPopupModalOpen={migrationActionPopupModalOpen} setMigrationActionPopupModalOpen={setMigrationActionPopupModalOpen}
                                                setActionCompletedRefreshSubscriptionTable={setActionCompletedRefreshSubscriptionTable} vendorAccountId={additionalDetails?.vendorAccountId} Logout={Logout}
                                                GetTableData={GetTableData} setToastSuccess={setToastSuccess} setToastError={setToastError} setToastMessage={setToastMessage} customerId={customerId}
                                                toggleDrawer={toggleDrawer} setSelectService={setSelectService} setSelectCategory={setSelectCategory} setIsActionInProgress={setIsActionInProgress} setIsAutoRefreshEnabled={setIsAutoRefreshEnabled}
                                            />
                                        }
                                        <Container fluid className="mb-5 p-0">
                                            <Row>
                                                <Col>
                                                    <div className={`table-responsive csp-gws-actions-table ${styles['csp-service-table']}`}>
                                                        {serverErrorUser && !loading &&
                                                            <div className={styles["no-data-user"]}>
                                                                <img src={EmptyDataTableFilterSearch} className="empty-user-management-table-svg" />
                                                                <h2 className="mt-4 mb-3">Facing issues in reaching servers, Try again later.</h2>
                                                            </div>
                                                        }
                                                        {emptyUserSearch && !loading && !emptyUserFilters && initialSubscriptionTableCall && !emptyUser &&
                                                            <div className={styles["no-data-user"]}>
                                                                <img src={EmptyDataTableFilterSearch} className="empty-user-table-svg" />
                                                                <h2 className="mt-4 mb-3">No Matching Subscriptions Found.</h2>
                                                            </div>
                                                        }
                                                        {emptyUserFilters && !loading && initialSubscriptionTableCall && !emptyUser &&
                                                            <div className={styles["no-data-user"]}>
                                                                <img src={EmptyDataTableFilterSearch} className="empty-user-table-svg" />
                                                                <h2 className="mt-4 mb-3">No Data Found.</h2>
                                                            </div>
                                                        }
                                                        {emptyUser && !loading && !emptyUserFilters && initialSubscriptionTableCall &&
                                                            <div className={styles["no-data-user"]}>
                                                                <img src={EmptyServiceSubscription} className="empty-customers-table-svg" />
                                                                <h2 className="mt-4 mb-3">No Subscriptions in this Cloud Service.</h2>
                                                                {((!resellerId || resellerId?.length==0) && !isCloudAdmin) &&<><p className="mb-3">Please click on Buy Subscriptions to Add Subscriptions</p>
                                                                <Button className="btn btn-lg btn-primary px-3" onClick={() => {
                                                                    navigate("/cloud-provision", {
                                                                        state: { customerId: customerId, serviceId: serviceId, isManagementConsoleNavigation: true }
                                                                    })
                                                                }}>Buy Subscription</Button>
                                                                </>}
                                                            </div>
                                                        }
                                                        {!initialSubscriptionTableCall && initialSubscriptionTableCall != null && !serverErrorUser && !emptyUser &&
                                                            <div className={styles["no-data-user"]}>
                                                                <img src={EmptyServiceProvision} className="empty-customers-table-svg" />
                                                                <h2 className="mt-4 mb-3">Selected Cloud Service is not provisioned</h2>
                                                                <p className="mb-3">No subscriptions are currently available for this cloud service.</p>
                                                                {((!resellerId || resellerId?.length==0) && !isCloudAdmin) && <Button className="btn btn-lg btn-primary px-3" onClick={() => {
                                                                    navigate("/cloud-provision", {
                                                                        state: { customerId: customerId, serviceId: serviceId, isManagementConsoleNavigation: true }
                                                                    })
                                                                }}>Provision Cloud Service</Button>}
                                                            </div>
                                                        }
                                                        {!serverErrorUser && !emptyUser && !emptyUserFilters && !emptyUserSearch && initialSubscriptionTableCall &&
                                                            <LazyTable
                                                                d={d}
                                                                table={table}
                                                                customStyles={customStyles}
                                                                loading={infinityLoading}
                                                                pageNo={pageNo}
                                                                totalRows={totalRows}
                                                                Expandable={Expandable}
                                                                handlePerRowsChange={handlePerRowsChange}
                                                                customerNameDefaultValue={customerNameDefaultValue}
                                                                GetAdditionalDetails={GetAdditionalDetails}
                                                                handlePageChange={handlePageChange}
                                                                styles={styles}
                                                                handleLazyCalls={handleLazyCalls}
                                                                serverErrorUser={serverErrorUser}
                                                                emptyUser={emptyUser}
                                                                emptyUserFilters={emptyUserFilters}
                                                                emptyUserSearch={emptyUserSearch}
                                                                initialSubscriptionTableCall={initialSubscriptionTableCall}
                                                                setScrollToDivTop={setScrollToDivTop}
                                                                scrollToDivTop={scrollToDivTop}
                                                            />}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </Col>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CspServiceManagement