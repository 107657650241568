import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import Popup from 'reactjs-popup';
import styles from './CSPChangeStatus.module.scss';
import { api } from '../../../../../../../../Utils/API';
import { CSP_CANCEL_STATUS_POST, CSP_REACTIVATE_STATUS_POST, CSP_SUSPEND_STATUS_POST } from '../../../../../../../../Utils/GlobalConstants';
import Table from 'react-bootstrap/Table';
import loadingGif from "../../../../../../../../Assets/Images/Animations/loading-management-console.gif"

const CSPChangeStatus = ({ subscriptionTableAPIData, setSubscriptionTableAPIData, subscriptionId, changeStatusActionPopupModalOpen, setChangeStatusActionPopupModalOpen,
    setToastSuccess, setToastError, setToastMessage, setActionCompletedRefreshSubscriptionTable, isNcePlan, setIsNcePlan, Logout,
setIsActionInProgress,
    setIsAutoRefreshEnabled  }) => {

    const [confirmSuspendFlag, setConfirmSuspendFlag] = useState(false);                                                                           // Confirmation popup on suspend/reactive/Cancel subscription                                
    const [confirmChangeStatusLoadingFlag, setConfirmChangeStatusLoadingFlag] = useState(false);                                                   // loading check in popup
    let ChangeStatusColumnNameSuspendNce = ["Plan", "SKU ID","Commitment Term (Billing Frequency)", "Reseller Price (Ex GST)", "Quantity", "Refund"];                     // Columns of table for Suspend Nce plan Action 
    let ChangeStatusColumnNameSuspendLegacy = ["Plan","SKU ID", "Commitment Term (Billing Frequency)", "Reseller Price (Ex GST)", "Quantity"];                            // Columns of table for Suspend Legacy plan Action 
    let ChangeStatusColumnNameReActivate = ["Plan","SKU ID", "Commitment Term (Billing Frequency)", "Reseller Price (Ex GST)", "Quantity"];                               // Columns of table for Re-Activate Legacy plan Action
    let ChangeStatusNceColumnNameCancel = ["Plan","SKU ID", "Commitment Term (Billing Frequency)", "Reseller Price (Ex GST)", "Quantity", "Cancellation Allowed Until"];  // Columns of table for Cancel NCE plan Action 
    const [selectedOption, setSelectedOption] = useState(null);                                                                                    // selcted tab whether suspend ,cancel or re-activate is selected in tab at the top                                                                // Selecting tab ie. suspend subscription,cancel subscription or re-activate subscription

    //selecting tab option at the top 
    const handleOptionChange = (value) => {
        {
            !subscriptionTableAPIData &&
                <div className={styles["loading-plans"]}><p><b>Loading . . .</b></p></div>
        }
        {
            subscriptionTableAPIData &&
                setSelectedOption(value);
        }
    };

    // Header Authorization for General API's
    const config = {
        headers: {
            "Authorization": `${localStorage.getItem("token_type")} ${localStorage.getItem("access_token")}`,
        },
    };

    //post api for Change-Status REACTIVATE confirmation
    async function CSPChangeStatusReactivatePost(subscriptionId) {
        setConfirmChangeStatusLoadingFlag(true);
        await api.post(`${CSP_REACTIVATE_STATUS_POST}?subscriptionId=${subscriptionId}`, null, config)
            .then(resp => {
                if (resp.status = 200) {
                    setConfirmChangeStatusLoadingFlag(false);
                    setSubscriptionTableAPIData(null);
                    setChangeStatusActionPopupModalOpen(false);
                    setToastMessage("Subscription is Reactivated Successfully.");
                    setToastSuccess(true);
                    setSelectedOption(null);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 5000);
                    setActionCompletedRefreshSubscriptionTable(true);
                     setIsActionInProgress(false);
                    console.log("auto refresh 1");
                    setIsAutoRefreshEnabled(true);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                setConfirmChangeStatusLoadingFlag(false);
                setToastMessage("Facing issue in reactivating subscription. Please try again later.");
                setToastError(true);
                setTimeout(() => {
                    setToastError(false);
                }, 5000);
                setIsActionInProgress(true);
                console.log("auto refresh 2");
                setIsAutoRefreshEnabled(false);

            })
    };

    //post api for Change-Status SUSPEND confirmation
    async function CSPChangeStatusSuspendPost(subscriptionId) {
        setConfirmChangeStatusLoadingFlag(true);
        await api.post(`${CSP_SUSPEND_STATUS_POST}?subscriptionId=${subscriptionId}`, null, config)
            .then(resp => {
                if (resp.status = 200) {
                    setConfirmChangeStatusLoadingFlag(false);
                    setSubscriptionTableAPIData(null);
                    setChangeStatusActionPopupModalOpen(false);
                    setSelectedOption(null);
                    setToastMessage("Subscription suspended successfully.");
                    setToastSuccess(true);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 5000);
                    setActionCompletedRefreshSubscriptionTable(true);
                    setIsActionInProgress(false);
                    console.log("auto refresh 1");
                    setIsAutoRefreshEnabled(true);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                setConfirmChangeStatusLoadingFlag(false);
                setToastMessage("We encountered an issue while suspending the subscription. Please try again later.");
                setToastError(true);
                setTimeout(() => {
                    setToastError(false);
                }, 5000);
                setIsActionInProgress(true);
                    console.log("auto refresh 1");
                    setIsAutoRefreshEnabled(false);

            })
    };

    //post api for Change-Status CANCEL confirmation
    async function CSPChangeStatusCancelPost(subscriptionId) {
        setConfirmChangeStatusLoadingFlag(true);
        await api.post(`${CSP_CANCEL_STATUS_POST}?subscriptionId=${subscriptionId}`, null, config)
            .then(resp => {
                if (resp.status = 200) {
                    setConfirmChangeStatusLoadingFlag(false);
                    setSubscriptionTableAPIData(null);
                    setChangeStatusActionPopupModalOpen(false);
                    setSelectedOption(null);
                    setToastMessage("Subscription is cancelled successfully.");
                    setToastSuccess(true);
                    setSelectedOption(null);
                    setTimeout(() => {
                        setToastSuccess(false);
                    }, 5000);
                    setActionCompletedRefreshSubscriptionTable(true);
                     setIsActionInProgress(false);
                    console.log("auto refresh 1");
                    setIsAutoRefreshEnabled(true);
                }
            })
            .catch(error => {
                if (error?.status == 401 || error?.response?.status == 401) {
                    Logout();
                }
                setConfirmChangeStatusLoadingFlag(false);
                setToastMessage("We encountered an issue while cancelling the subscription. Please try again later.");
                setToastError(true);
                setTimeout(() => {
                    setToastError(false);
                }, 5000);
                 setIsActionInProgress(true);
                    console.log("auto refresh 1");
                    setIsAutoRefreshEnabled(false);

            })
    };

    // for displaying default table when popup is opened
    useEffect(() => {
        if (subscriptionTableAPIData && subscriptionTableAPIData.status === 'ACTIVE') {
            // console.log('useEffect is running: active',selectedOption);
            setSelectedOption('suspend');
        } else if (subscriptionTableAPIData && subscriptionTableAPIData.status === 'SUSPENDED') {
            // console.log('useEffect is running: suspend');
            setSelectedOption('reactivate');
        }
    }, [subscriptionTableAPIData]);

    return (
        <div>
            {/* For legacy and nce plan reactivate and suspend popup - confirmation */}
            <Popup
                className="custom-modal custom-modal-md"
                open={confirmSuspendFlag}
                onClose={() => { setConfirmSuspendFlag(false); }}
                modal
                nested
            >
                {(close) => (
                    <div className="modal">
                        <div className="header p-4 py-3">
                            <h2 className="mb-0 px-3">Change Status</h2>
                            <XLg
                                size={18}
                                className="cpointer text-strong close-icon-popup-sm"
                                onClick={close}
                                color="#6A6A6A"
                            />
                        </div>
                        <Container className={`p-5 `}>
                            <p className={`pt-0 px-3 pb-5 mb-0`}>
                                {subscriptionTableAPIData && selectedOption !== 'cancel' && (subscriptionTableAPIData.status === "ACTIVE" ? <strong>Are you sure you want to Suspend Subscription?</strong> : <strong>Are you sure you want to Re-Activate Subscription?</strong>)}
                                {subscriptionTableAPIData && selectedOption === 'cancel' && <strong>Are you sure you want to Cancel Subscription?</strong>}

                            </p>
                            <div className="actions">
                                <div className="d-flex justify-content-center pb-3 pt-2">
                                    <Button
                                        className="btn btn-lg mx-3 btn-border-light"
                                        variant="light"
                                        onClick={() => {
                                            close();
                                        }}
                                    >
                                        No
                                    </Button>
                                    <Button
                                        className="btn btn-md"
                                        onClick={() => {
                                            { subscriptionTableAPIData && selectedOption !== 'cancel' && (subscriptionTableAPIData.status === "ACTIVE" ? CSPChangeStatusSuspendPost(subscriptionId) : CSPChangeStatusReactivatePost(subscriptionId)) };
                                            { subscriptionTableAPIData && selectedOption === 'cancel' && CSPChangeStatusCancelPost(subscriptionId) }
                                            close();
                                        }}
                                    >
                                        Yes
                                    </Button>
                                </div>
                            </div>
                        </Container>
                    </div>
                )}
            </Popup>

            {/* Change status Popup */}
            <Popup
                className="custom-modal custom-modal-xl"
                open={changeStatusActionPopupModalOpen}
                onClose={() => { setChangeStatusActionPopupModalOpen(false); setSubscriptionTableAPIData(null); setIsNcePlan(null); }}
                modal
                closeOnDocumentClick={!confirmChangeStatusLoadingFlag}
                nested
            >
                {(close) => (
                    <div className="modal">
                        <>
                            <div className="header">
                                <h2>Change Status</h2>
                                <XLg
                                    size={18}
                                    className="cpointer text-strong"
                                    onClick={() => { if (!confirmChangeStatusLoadingFlag) close() }}
                                    color="#6A6A6A"
                                />
                            </div>
                            {/* For legacy plan */}
                            {!isNcePlan &&
                                <Container fluid className={`upgrade-modal-height ${styles['modal-width']}`}>
                                    <Box sx={{ width: '100%' }} className='mt-4 mb-4'>
                                        <React.Fragment>
                                            <Typography sx={{ mb: 1 }}>
                                                {subscriptionTableAPIData && (subscriptionTableAPIData.status === "ACTIVE" ? <p className='text-strong'>Suspend Subscription</p> : <p className='text-strong'>Re - Activate Subscription</p>)}
                                                <div className={`table-responsive ${styles['gws-downgrade-core']}`}>
                                                    {subscriptionTableAPIData &&
                                                        <Table className={`${styles['csp-change-status-core-table']}`} responsive="md">
                                                            <thead className="sticky-top">
                                                                <tr>
                                                                    {subscriptionTableAPIData.status === "ACTIVE" ? ChangeStatusColumnNameSuspendLegacy.map(th => {
                                                                        return (<th className={(th === "Reseller Price (Ex GST)" || th === "Quantity") ? "text-right" : ""}>{th}</th>)
                                                                    }) :
                                                                        ChangeStatusColumnNameReActivate.map(th => {
                                                                            return (<th className={(th === "Reseller Price (Ex GST)" || th === "Quantity") ? "text-right" : ""}>{th}</th>)
                                                                        })
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    <tr>
                                                                        <td>{subscriptionTableAPIData && subscriptionTableAPIData.planName}</td>
                                                                        <td>{subscriptionTableAPIData.planCode}</td>
                                                                        <td>{subscriptionTableAPIData && subscriptionTableAPIData.billingTerm}</td>
                                                                        {subscriptionTableAPIData && <td className='text-right'>${subscriptionTableAPIData.price}</td>}
                                                                        <td className='text-right'>{subscriptionTableAPIData && subscriptionTableAPIData.quantity}</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    }

                                                    {subscriptionTableAPIData && subscriptionTableAPIData.status === "ACTIVE" &&
                                                        <div className='text-small'>
                                                            <span className='text-extra-strong'>Note :</span><span className='mx-1'>Although pausing customer access to service immediately blocks customer’s ability to use services , this doesn’t impact your invoice.</span>
                                                            <p className='mx-9'>You’ll continue to be billed as normal. Pausing this will turn off and disable auto-renew and will delete any scheduled changes.</p>
                                                        </div>
                                                    }
                                                    {!subscriptionTableAPIData &&
                                                        <div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plan . . .</p></div>
                                                    }
                                                </div>
                                            </Typography>
                                            {/* buttons for change status legacy plan */}
                                            <div className='text-right pt-4'>
                                                <React.Fragment>
                                                    {!confirmChangeStatusLoadingFlag && subscriptionTableAPIData && <Button
                                                        onClick={() => setConfirmSuspendFlag(true)}
                                                    >
                                                        {subscriptionTableAPIData.status === "ACTIVE" ? "Suspend Subscription" : "Re-Activate Subscription"}
                                                    </Button>}
                                                    {confirmChangeStatusLoadingFlag && subscriptionTableAPIData && <Button
                                                        disabled
                                                        className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                        variant="light"
                                                    >
                                                        Processing . . .
                                                    </Button>}
                                                </React.Fragment>
                                            </div>
                                        </React.Fragment>

                                    </Box>
                                </Container>}
                            {/* For Nce plan */}
                            {isNcePlan &&
                                <>
                                    <Container fluid className={`upgrade-modal-height ${styles['modal-width']}`}>
                                        <Box sx={{ width: '100%' }} className='mt-4 mb-4'>
                                            <React.Fragment>
                                                <Typography sx={{ mb: 1 }}>
                                                    <Container fluid>
                                                        {/* Top note showing cancellation note */}
                                                        {subscriptionTableAPIData && (subscriptionTableAPIData.isCancellationAllowed
                                                            ? <p className='text-small'><span className='text-extra-strong'>Note : </span>Cancellation allowed until {subscriptionTableAPIData.cancellationAllowedUntilDate}</p>
                                                            : <p className='text-small'><span className='text-extra-strong'>Note : </span>Your 7 Day Cancellation window is closed.</p>)
                                                        }
                                                        <Row>
                                                            {/* 1st button tab: SUSPEND */}
                                                            {subscriptionTableAPIData && subscriptionTableAPIData.status === 'ACTIVE' &&
                                                                <Col md={3}>
                                                                    <label className='text-strong' >
                                                                        <input
                                                                            className='mr-1'
                                                                            type="radio"
                                                                            name="subscriptionOption"
                                                                            value="suspend"
                                                                            style={{ verticalAlign: 'top' }}
                                                                            checked={selectedOption === 'suspend'}
                                                                            onChange={() => handleOptionChange('suspend')}
                                                                        />
                                                                        Suspend Subscription
                                                                    </label>
                                                                </Col>}

                                                            {/* 2nd button tab: REACTIVATE */}
                                                            {subscriptionTableAPIData && subscriptionTableAPIData.status === 'SUSPENDED' &&
                                                                <Col md={3}>
                                                                    <label className='text-strong'>
                                                                        <input
                                                                            className='mr-1'
                                                                            type="radio"
                                                                            name="subscriptionOption"
                                                                            value="reactivate"
                                                                            style={{ verticalAlign: 'top' }}
                                                                            checked={selectedOption === 'reactivate'}
                                                                            onChange={() => handleOptionChange('reactivate')}
                                                                        />
                                                                        Re-Activate Subscription
                                                                    </label>
                                                                </Col>}
                                                            {/* 3rd button tab: CANCEL */}
                                                            {subscriptionTableAPIData && subscriptionTableAPIData.isCancellationAllowed === true &&
                                                                <Col md={3}>
                                                                    <label className='text-strong'>
                                                                        <input
                                                                            className='mr-1'
                                                                            type="radio"
                                                                            name="subscriptionOption"
                                                                            value="cancel"
                                                                            style={{ verticalAlign: 'top' }}
                                                                            checked={selectedOption === 'cancel'}
                                                                            onChange={() => handleOptionChange('cancel')}
                                                                        />
                                                                        Cancel Subscription
                                                                    </label>
                                                                </Col>}
                                                            {/* 4th Button tab: cancel disabled */}
                                                            {subscriptionTableAPIData && subscriptionTableAPIData.isCancellationAllowed === false &&
                                                                <Col md={3}>
                                                                    <label className='text-disabled text-strong'>
                                                                        <input
                                                                            className='mr-1'
                                                                            type="radio"
                                                                            name="subscriptionOption"
                                                                            value="disabled Cancel"
                                                                            style={{ verticalAlign: 'top' }}
                                                                            disabled
                                                                        />
                                                                        Cancel Subscription
                                                                    </label>
                                                                </Col>}
                                                        </Row>

                                                    </Container>
                                                    <div className={`table-responsive mt-4 ${styles['gws-downgrade-core']}`}>

                                                        {/* suspend reactivate nce subscription table */}
                                                        {(selectedOption === 'suspend' || 'reactivate' && selectedOption !== 'cancel') &&
                                                            <>
                                                                {/* loading state while table is not loaded */}
                                                                {!subscriptionTableAPIData &&
                                                                    <div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plan . . .</p></div>
                                                                }
                                                                {subscriptionTableAPIData &&
                                                                    <Table className={`${styles['csp-change-status-core-table']}`} responsive="md">
                                                                        <thead className="sticky-top">
                                                                            <tr>
                                                                                {subscriptionTableAPIData.status === "ACTIVE" ? ChangeStatusColumnNameSuspendNce.map(th => {
                                                                                    return (<th className={(th === "Reseller Price (Ex GST)" || th === "Quantity") ? "text-right" : ""}>
                                                                                        {th}
                                                                                    </th>)
                                                                                }) :
                                                                                    ChangeStatusColumnNameReActivate.map(th => {
                                                                                        return (<th className={(th === "Reseller Price (Ex GST)" || th === "Quantity") ? "text-right" : ""}>
                                                                                            {th}
                                                                                        </th>)
                                                                                    })
                                                                                }
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                <tr>
                                                                                    <td>{subscriptionTableAPIData.planName}</td>
                                                                                    <td>{subscriptionTableAPIData.planCode}</td>
                                                                                    <td>{subscriptionTableAPIData.billingTerm}</td>
                                                                                    <td className='text-right'>${subscriptionTableAPIData.price}</td>
                                                                                    <td className='text-right'>{subscriptionTableAPIData.quantity}</td>
                                                                                    {subscriptionTableAPIData.status === "ACTIVE" && <td> {subscriptionTableAPIData.isCancellationAllowed ? "Yes" : "No"}</td>}
                                                                                </tr>
                                                                            }
                                                                        </tbody>
                                                                    </Table>}
                                                            </>
                                                        }

                                                        {/* cancel subscription */}
                                                        {subscriptionTableAPIData && selectedOption === 'cancel' &&
                                                            <>{/* loading state while table is not loaded */}
                                                                {!subscriptionTableAPIData &&
                                                                    <div className={styles["loading-plans"]}><img className="management-console-loading-icon" src={loadingGif} /><p className="text-medium text-strong management-console-loading-text">Loading Plan . . .</p></div>
                                                                }
                                                                {subscriptionTableAPIData &&
                                                                    <Table className={`${styles['csp-change-status-core-table']}`} responsive="md">
                                                                        <thead className="sticky-top">
                                                                            <tr>
                                                                                {ChangeStatusNceColumnNameCancel.map(th => {
                                                                                    return (
                                                                                        <th className={(th === "Reseller Price (Ex GST)" || th === "Quantity") ? "text-right" : ""}>
                                                                                            {th}
                                                                                        </th>
                                                                                    );
                                                                                })}
                                                                            </tr>

                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                <tr>
                                                                                    <td>{subscriptionTableAPIData.planName}</td>
                                                                                    <td>{subscriptionTableAPIData.planCode}</td>
                                                                                    <td>{subscriptionTableAPIData.billingTerm}</td>
                                                                                    <td className='text-right'>${subscriptionTableAPIData.price}</td>
                                                                                    <td className='text-right'>{subscriptionTableAPIData.quantity}</td>
                                                                                    <td>{subscriptionTableAPIData.cancellationAllowedUntilDate}</td>
                                                                                </tr>
                                                                            }
                                                                        </tbody>
                                                                    </Table>}
                                                            </>
                                                        }
                                                        {/* last note in the screen for suspend and cancel subscription */}
                                                        {subscriptionTableAPIData && selectedOption === 'cancel' &&
                                                            <p className='text-small'><span className='text-extra-strong'>Note :</span> This is permanent deletion of subscription and it cannot be undone.
                                                                <p className='mx-9'>Full refund will be given incase subscription is cancelled within 24 hours of purchase. Post 24 hours proration charges will be applied.</p> </p>
                                                        }
                                                        {subscriptionTableAPIData && subscriptionTableAPIData.status === "ACTIVE" && selectedOption === "suspend" &&
                                                            <div className='text-small'>
                                                                <span className='text-extra-strong'>Note :</span><span className='mx-1'>Although pausing customer access to service immediately blocks customer’s ability to use services , this doesn’t impact your invoice.</span>
                                                                <p className='mx-9 mb-0'>You’ll continue to be billed as normal. Pausing this will turn off and disable auto-renew and will delete any scheduled changes.</p>
                                                                <p className='mx-9'>You can re-activate the subscription in future till your renewal date from change status tab.</p>
                                                            </div>
                                                        }
                                                        {/* buttons for change status */}
                                                        <div className='text-right pt-4'>
                                                            <React.Fragment>
                                                                {/* re-active and suspend button */}
                                                                {!confirmChangeStatusLoadingFlag && subscriptionTableAPIData && (selectedOption === 'reactive' || 'suspend' && selectedOption !== 'cancel') && <Button
                                                                    onClick={() => setConfirmSuspendFlag(true)}
                                                                >
                                                                    {subscriptionTableAPIData && subscriptionTableAPIData.status === "ACTIVE" ? "Suspend Subscription" : "Re-Activate Subscription"}
                                                                </Button>}
                                                                {/* cancel button */}
                                                                {!confirmChangeStatusLoadingFlag && subscriptionTableAPIData && selectedOption === 'cancel' && <Button
                                                                    onClick={() => setConfirmSuspendFlag(true)}
                                                                > Cancel Subscription
                                                                </Button>}

                                                                {confirmChangeStatusLoadingFlag && subscriptionTableAPIData && <Button
                                                                    disabled
                                                                    className="ml-3 btn-border-light w-md-auto col-xs-12"
                                                                    variant="light"
                                                                >
                                                                    Processing . . .
                                                                </Button>}
                                                            </React.Fragment>
                                                        </div>
                                                    </div>
                                                </Typography>

                                            </React.Fragment>

                                        </Box>
                                    </Container>

                                </>
                            }
                        </>
                    </div>
                )
                }
            </Popup >
        </div >
    )
}


export default CSPChangeStatus